/**
 * FeatureFlags store
 * @namespace FeatureFlags
 */

/**
 * @memberof FeatureFlags
 * @typedef {{
 *  [flagName: string]: boolean;
 * }} FeatureFlagData
 */

/**
 * @memberof FeatureFlags
 * @typedef {Object} FeatureFlagsStore
 * @property {FeatureFlagData} featureFlags
 * @property {String} error
 */

/**
 * @function FeatureFlagsStoreCreator
 * @memberof FeatureFlags
 * @returns {FeatureFlagsStore}
 */
const featureFlagsStoreCreator = () => ({
  featureFlags: {},
  featureFlagsLoading: {},
  error: '',
})

export default featureFlagsStoreCreator
