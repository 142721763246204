/**
 * Clinicians store
 * @namespace Clinicians
 */

/**
 * @memberof Clinicians
 * @typedef {Object} Clinician
 * @property {string} uuid
 * @property {string} email
 * @property {string} name
 * @property {string} status
 * @property {string} practitioner_type
 */

/**
 * @memberof Clinicians
 * @typedef {Object} CliniciansStore
 * @property {Clinician[]} clinicians
 * @property {String} error
 * @property {boolean} loading
 */

/**
 * @function CliniciansStoreCreator
 * @memberof Clinicians
 * @returns {CliniciansStore}
 */
const CliniciansStoreCreator = () => ({
  clinicians: [],
  error: '',
  loading: false,
})

export default CliniciansStoreCreator
