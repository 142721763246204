import Vue from 'vue'

import VueRouter from 'vue-router'
import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'

import Clinician from '@/services/Clinician'
import Websocket from '@/plugins/Websocket'
import Airtable from '@/services/Airtable'
import Stripe from '@/services/Stripe'

import Modal from '@/utils/Modal'
import Snackbar from '@/utils/Snackbar'
import Earnings from '@/utils/Earnings'
import Request from '@/utils/Request'

import authConfig from '@/config/auth'
import routes from './routes'

import EventBus from '@/utils/Bus'
import { updateStatsigUserId, getExperimentFlag } from '@/services/Statsig'
import Skilljar from '@/services/Skilljar'
import { determineAuth0Override } from '@/router/utils'

const { issuer, clientId, scopes } = authConfig.oidc

const oktaAuth = new OktaAuth({
  issuer,
  clientId,
  redirectUri: window.location.origin + '/implicit/callback',
  scopes,
  transformAuthState: determineAuth0Override,
})

oktaAuth.authStateManager.subscribe(async ({ isAuthenticated }) => {
  if (!isAuthenticated) {
    EventBus.$emit('logout')
  } else {
    try {
      const { idToken: { claims } = {} } =
        oktaAuth.authStateManager.getAuthState()

      await updateStatsigUserId(claims?.email)

      const isAuth0Tester = await getExperimentFlag(
        'auth0progressiverollout',
        'auth0Enabled',
        false
      )

      if (isAuth0Tester) {
        sessionStorage.setItem('auth0Tester', true)
        await oktaAuth.signOut()
        router.push({ path: '/auth0Login' })
      }
    } catch (e) {
      sessionStorage.setItem('auth0Tester', false)
    }
  }
})

Vue.use(VueRouter)
Vue.use(OktaVue, {
  oktaAuth,
  onAuthRequired: () => {
    if (router.currentRoute.path !== '/login') {
      router.push({ path: '/login' })
    }
  },
})

Vue.use(Websocket)
Vue.use(Modal)
Vue.use(Snackbar)
Vue.use(Earnings)
Vue.use(Clinician)
Vue.use(Airtable)
Vue.use(Request)
Vue.use(Stripe)
Vue.use(Skilljar)

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
})

router.afterEach((to) => {
  Vue.prototype.$dd.startView(to.path)
})

export default router
