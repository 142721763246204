import Vue from 'vue'
import VueAnime from 'vue-animejs'
import VueMeta from 'vue-meta'

import authConfig from '@/config/auth'
import { initializeStatsig } from '@/services/Statsig'

import App from './App.vue'

import router from './router'
import Store from './plugins/Store'
import DataDog from './plugins/DataDog'
import vuetify from './plugins/vuetify'
import zendesk from './plugins/zendesk'
import beamer from './plugins/beamer.js'
import VueGtag from 'vue-gtag'

import './assets/scss/_all.scss'
import { Auth0Plugin } from '@/plugins/auth0'

Vue.config.productionTip = false

initializeStatsig()

Vue.use(DataDog)
Vue.use(VueMeta)
Vue.use(Store)
Vue.use(VueAnime)
Vue.use(zendesk)
Vue.use(beamer)

const options = {
  domain: authConfig.auth0.domain,
  clientId: authConfig.auth0.clientId,
  useRefreshTokens: true,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  },
  authorizationParams: {
    audience: authConfig.auth0.audience,
  },
}
Vue.use(Auth0Plugin, options)

Vue.use(
  VueGtag,
  {
    config: { id: 'G-JW5JD3Z98W' },
  },
  router
)

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
