import { createNamespacedHelpers } from 'vuex'
import { NAMESPACE } from './state'
import { mapKeys } from '@/store/helpers'
const { mapGetters } = createNamespacedHelpers(NAMESPACE)

export const getters = {
  /**
   * Returns a boolean representing the hidden state of the dashboard store.
   * @param {DashboardStore} state - The dashboard store.
   * @returns {boolean} The boolean representing the hidden state of the dashboard.
   */
  getHidden: (state) => state.hidden,
}

export const mappedGetters = mapGetters(Object.keys(getters))
export const getterKeys = mapKeys(getters, NAMESPACE)
export default getters
