import { FeedbackConstants } from '@enzyme-health/common-models'

const { Category, Level, Status, SubCategory, Type } = FeedbackConstants

export const categories = {
  [Category.CLINICAL]: [
    {
      title: SubCategory.OUTSTANDING_AUDIT,
      defaultLevel: null,
    },
    {
      title: SubCategory.DOWNGRADED_FLAG,
      defaultLevel: Level.ZERO,
    },
    {
      title: SubCategory.NONADHERENCE_CLIENT_CLINICAL_GUIDELINES,
      defaultLevel: Level.ONE,
    },
    {
      title: SubCategory.NONADHERENCE_DECISION,
      defaultLevel: Level.TWO,
    },
    {
      title: SubCategory.NONADHERENCE_DECISION,
      defaultLevel: Level.THREE,
    },
  ],
  [Category.PROFESSIONAL]: [
    {
      title: SubCategory.UNPROFESSIONAL,
      defaultLevel: Level.TWO,
    },
  ],
}

export const types = {
  [Type.FLAG]: 'Performance Flag',
  [Type.POSITIVE]: 'Positive Feedback',
}

export const levels = [Level.ZERO, Level.ONE, Level.TWO, Level.THREE]

export const status = Status

export const oldCategories = {
  Clinical: [
    'Outstanding audit',
    'Clinical Flag - Severity 0',
    'Non-adherence to documentation guidelines',
    'Non-adherence to QA process',
    'Non-adherence to clinical guidelines',
    'Failure to elicit additional information from patient',
    'Non-adherence to Clinical Decision Making - Level 2',
    'Non-adherence to Clinical Decision Making - Level 3',
  ],
  Operational: [
    'Late or Missed Shift',
    'Dropped/Changed Shift',
    'Nonadherence to workflow &/or technical guidelines',
    'Late or Missed onboarding or Meeting',
    'Operational Flag - Severity 0',
  ],
  Professional: ['Unprofessional Conduct'],
}

export const oldStatus = {
  RESOLVED: 'Resolved',
  OPENED: 'Opened',
  NOTIFIED: 'Notified',
  DRAFT: 'Draft',
}

export default class Feedback {
  constructor(feedback) {
    this.id = feedback.id
    this.type = feedback.type
    this.status = feedback.status
    this.category = feedback.category
    this.description = feedback.description
    this.subCategory = feedback.subCategory
    this.consultUrls = feedback.consultUrls
    this.referenceUrls = feedback.referenceUrls
    this.notifyClinician = !!feedback.notifyClinician
    this.source = feedback.source

    // Optional
    this.level = feedback.level
    this.createdBy = feedback.createdBy
    this.reportedBy = feedback.reportedBy
    this.updatedBy = feedback.updatedBy
    this.resolvedBy = feedback.resolvedBy
    this.clientUUID = feedback.clientUUID
    this.clinicianUUID = feedback.clinicianUUID
    this.updatedAt = convertToDateOrNull(feedback.updatedAt)
    this.reportedAt = convertToDateOrNull(feedback.reportedAt)
    this.notifiedAt = convertToDateOrNull(feedback.notifiedAt)
    this.readAt = convertToDateOrNull(feedback.readAt)
    this.createdAt = convertToDateOrNull(feedback.createdAt)
    this.resolvedAt = convertToDateOrNull(feedback.resolvedAt)
    this.client = feedback.client ? new Client(feedback.client) : null
    this.clinician = feedback.clinician
      ? new Clinician(feedback.clinician)
      : null
    this.outcomeNotes = feedback.outcomeNotes
  }

  static fromJSON(feedbackResponse) {
    return new Feedback({
      id: feedbackResponse.id,
      type: feedbackResponse.type,
      status: feedbackResponse.status,
      category: feedbackResponse.category,
      description: feedbackResponse.description,
      subCategory: feedbackResponse.sub_category,
      consultUrls: feedbackResponse.consult_urls,
      referenceUrls: feedbackResponse.reference_urls,
      notifyClinician: !!feedbackResponse.notify_clinician,
      level: feedbackResponse.level,
      createdBy: feedbackResponse.created_by,
      reportedBy: feedbackResponse.reported_by,
      resolvedBy: feedbackResponse.resolved_by,
      updatedBy: feedbackResponse.updated_by,
      clientUUID: feedbackResponse.client_uuid,
      clinicianUUID: feedbackResponse?.clinician?.uuid,
      updatedAt: convertToDateOrNull(feedbackResponse.updated_at),
      reportedAt: convertToDateOrNull(feedbackResponse.reported_at),
      notifiedAt: convertToDateOrNull(feedbackResponse.notified_at),
      readAt: convertToDateOrNull(feedbackResponse.read_at),
      createdAt: convertToDateOrNull(feedbackResponse.created_at),
      resolvedAt: convertToDateOrNull(feedbackResponse.resolved_at),
      client: feedbackResponse.client ? feedbackResponse.client : null,
      clinician: feedbackResponse.clinician ? feedbackResponse.clinician : null,
      outcomeNotes: feedbackResponse.outcome_notes,
      source: feedbackResponse.source,
    })
  }

  /**
   * Converts a `Feedback` entity to a DTO object suitable for creating/updating.
   */
  toDTO() {
    return {
      client_uuid: this.client?.uuid,
      category: this.category,
      sub_category: this.subCategory,
      type: this.type,
      level: this.level,
      consult_urls: this.consultUrls,
      description: this.description,
      notify_clinician: this.notifyClinician,
      reference_urls: this.referenceUrls,
      clinician_uuid: this.clinician?.uuid,
      outcome_notes: this.outcomeNotes,
      source: this.source,
    }
  }
}

class Client {
  constructor(clientResponse) {
    this.uuid = clientResponse.uuid || clientResponse.id
    this.name = clientResponse.name
  }
}

class Clinician {
  constructor(clinicianResponse) {
    this.email = clinicianResponse.email
    this.name = clinicianResponse.name
    this.uuid = clinicianResponse.uuid
    this.status = clinicianResponse.status
    this.practitioner_type = clinicianResponse.practitioner_type
    this.photo = clinicianResponse.photo ?? null
  }
}

function convertToDateOrNull(dateStr) {
  if (!dateStr) return null

  let date
  try {
    date = new Date(dateStr)
  } catch {
    date = null
  }

  return date
}
