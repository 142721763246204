import { createNamespacedHelpers } from 'vuex'
import { mapKeys } from '@/store/helpers'
import { keys as m } from './mutations'
import EventBus from '@/utils/Bus'
import { NAMESPACE } from './state'

const actions = {
  updateAnnouncementsCount({ commit }, num) {
    commit(m.updateAnnouncements, num)
  },
  showToast(_, data) {
    EventBus.$emit('toast', data)
  },
  toggleDrawer({ commit }) {
    commit(m.toggleDrawer)
  },
  closeDrawer({ commit }) {
    commit(m.closeDrawer)
  },
}

const { mapActions } = createNamespacedHelpers(NAMESPACE)
export const mappedActions = mapActions(Object.keys(actions))
export const actionKeys = mapKeys(actions, NAMESPACE)
export default actions
