import {
  PractitionerType,
  PractitionerTypes,
} from '@enzyme-health/common-models/dist/Common/Constants/PractitionerConstants'

export function getPractitionerDisplayName(type) {
  return PractitionerTypes.filter((p) => p.value == type)?.display
}

export default PractitionerType
