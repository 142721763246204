/**
 * Vue store for dashboard
 * @typedef {Object} dashboard
 * @property {boolean} hidden - Whether dashboard is hidden
 */

export const NAMESPACE = 'dashboard'

export default () => ({
  hidden: true,
})
