import { createNamespacedHelpers } from 'vuex'
import { NAMESPACE } from './constants'
const { mapGetters } = createNamespacedHelpers(NAMESPACE)

export const getters = {
  /**
   * Returns true if the typeform is toggled, otherwise false.
   */
  isTypeformToggled: (state) => state.isToggled,

  /**
   * Returns the first object of the Array of Forms. (active one)
   */
  getActiveTypeformForm: (state) => state.forms?.[0],

  /**
   * Returns the Array of Forms.
   */
  getTypeformForms: (state) => state.forms,
}

export const mappedGetters = mapGetters(Object.keys(getters))
export default getters
