import ErrorContainer from '@/components/ErrorContainer.vue'
import ResponseError from '@/utils/ResponseError'
import { dayjs } from '@/utils/Date'
import { authGuard } from '@/utils/auth0Guard'
import vueEnv from '@/utils/env'

export const DASHBOARD_ROUTE = 'dashboard'
export const VIEW_FEEDBACK_ROUTE = 'ViewFeedback'
export const EDIT_FEEDBACK_ROUTE = 'EditFeedback'
export const ODAPI = vueEnv.onDemandApi

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: DASHBOARD_ROUTE,
    component: async () => await import('@/views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: authGuard,
  },
  /** Temprorary route for AMZ issue */
  {
    path: '/consult-waiting-room/:id',
    name: 'consult-waiting-room',
    component: async () => await import('@/views/ConsultWaitingRoom.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: authGuard,
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: async () => await import('@/views/Welcome.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: authGuard,
  },
  {
    path: '/earnings',
    component: async () =>
      (await import('@/views/earnings/views')).EarningsSummary,
    name: 'earnings-summary',
    meta: {
      requiresAuth: true,
    },
    beforeEnter: authGuard,
  },
  {
    path: '/earnings/:period',
    component: async () =>
      (await import('@/views/earnings/views')).EarningsHistory,
    name: 'earnings-history',
    props: (route) => {
      return {
        min: dayjs(route.params.period).startOf('month').toDate(),
        max: dayjs(route.params.period).endOf('month').toDate(),
      }
    },
    meta: {
      requiresAuth: true,
    },
    beforeEnter: authGuard,
  },
  {
    path: '/earnings/:period/summary',
    component: async () =>
      (await import('@/views/earnings/views')).EarningsPeriodSummary,
    name: 'earnings-period-summary',
    props: (route) => {
      return {
        period: route.params.period,
      }
    },
    meta: {
      requiresAuth: true,
    },
    beforeEnter: authGuard,
  },
  {
    path: '/metrics',
    name: 'metrics',
    component: async () => await import('@/views/Metrics.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: authGuard,
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: async () => await import('@/views/Schedule.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: authGuard,
  },
  {
    path: '/trainings',
    redirect: '/education',
  },
  {
    path: '/education',
    name: 'education',
    component: async () => await import('@/views/Education.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: authGuard,
  },
  {
    path: '/consults/:status',
    name: 'consults',
    component: async () => await import('@/views/Consults.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: authGuard,
  },
  {
    path: '/account/:tab?',
    name: 'account',
    component: async () => await import('@/views/account/Account.vue'),
    props: (route) => ({
      tab: route.params.tab,
    }),
    meta: {
      queryParamNavigation: true, // We want to track query params in datadog views
      requiresAuth: true,
    },
    beforeEnter: authGuard,
  },
  {
    path: '/opportunities',
    name: 'opportunities',
    component: async () => await import('@/views/opportunities/Index.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: authGuard,
  },
  {
    path: '/opportunities/:id',
    name: 'opportunity',
    props: (route) => ({ opportunityId: Number(route.params.id) }),
    component: async () => await import('@/views/opportunities/Details.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: authGuard,
  },
  {
    path: '/clinician-performance-feedback',
    name: 'clinician-performance-feedback-list',
    component: () => import('@/views/clinician-performance-feedback/List.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: authGuard,
  },
  {
    path: '/clinician-performance-feedback/create',
    name: 'clinician-performance-feedback-create',
    component: () =>
      import('@/views/clinician-performance-feedback/Create.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: authGuard,
  },
  {
    path: '/clinician-performance-feedback/:id',
    component: () =>
      import('@/views/clinician-performance-feedback/FeedbackWrapper.vue'),
    props: (route) => ({
      feedbackId: route.params.id,
    }),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: VIEW_FEEDBACK_ROUTE,
        component: () =>
          import('@/views/clinician-performance-feedback/View.vue'),
      },
      {
        path: 'edit',
        name: EDIT_FEEDBACK_ROUTE,
        component: () => import('@/components/Feedback/Form/FeedbackForm.vue'),
      },
    ],
  },
  {
    path: '/feedback/:id',
    name: 'feedback',
    props: (route) => ({ feedbackId: Number(route.params.id) }),
    component: async () => await import('@/views/feedback/Details.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: authGuard,
  },
  {
    path: '/stripe/authorize',
    component: async () => await import('@/views/stripe/Authorize.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: authGuard,
  },
  {
    path: '/assignments',
    redirect: '/dashboard',
    component: async () =>
      await import('@/views/assignments/AssignmentsLayout.vue'),
    children: [
      {
        path: 'expired',
        component: async () =>
          await import('@/views/assignments/views/Expired.vue'),
      },
      {
        path: 'canceled',
        component: async () =>
          await import('@/views/assignments/views/Canceled.vue'),
      },
      {
        path: 'prev-declined',
        component: async () =>
          await import('@/views/assignments/views/PrevDeclined.vue'),
      },
      {
        path: 'completed',
        component: async () =>
          await import('@/views/assignments/views/Completed.vue'),
      },
      {
        path: 'error',
        component: async () =>
          await import('@/views/assignments/views/Error.vue'),
      },
    ],
  },
  {
    path: '/assignments/:hash/accept',
    component: async () => await import('@/views/assignments/views/Accept.vue'),
    props: (route) => ({ assignmentHash: route.params.hash }),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: authGuard,
  },
  {
    path: '/login',
    component: async () => await import('@/views/LoginPage.vue'),
  },
  {
    path: '/auth0Login',
    component: async () => await import('@/components/Auth/Auth0Callback.vue'),
  },
  {
    path: '/implicit/callback',
    component: async () => await import('@/components/Auth/OktaCallback.vue'),
  },
  {
    path: '/login-help',
    component: async () => await import('@/views/LoginHelp.vue'),
  },
  {
    path: '*',
    component: ErrorContainer,
    props: {
      error: new ResponseError('Page Not Found', 404),
    },
  },
]

export default routes
