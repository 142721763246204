import state from './state'
import actions, { mappedActions } from './actions'
import getters, { mappedGetters } from './getters'
import mutations from './mutations'

export const featureFlagsHelpers = {
  mappedActions,
  mappedGetters,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
