import PractitionerType from '@/utils/PractitionerType'

export default {
  earningsSummaryMonth(state) {
    return state?.earnings?.summaryMonth?.data
  },

  /**
   * Returns a boolean indicating if a clinician is an RN or not
   * @param {CliniciansStore} state - The clinician store
   * @returns boolean
   */
  isRegisteredNurse: (state) => {
    return state.clinician?.practitioner_type == PractitionerType.RN
  },

  /**
   * Returns practitioner type of clinician in state
   * @param {CliniciansStore} state - The clinician store
   * @returns string
   */
  practitionerType: (state) => {
    return state.clinician?.practitioner_type
  },
}
