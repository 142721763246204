import Vue from 'vue'

export async function determineAuth0Override(oktaAuth, authState) {
  const auth0Enabled = Vue.prototype.$auth0.isAuth0Enabled
  // If auth0 is enabled, assume okta auth to be valid
  if (auth0Enabled) {
    authState.isAuthenticated = true
  }
  return authState
}
