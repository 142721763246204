const Snackbar = {
  install(Vue) {
    const proto = Vue.prototype
    Vue.prototype.$snackbar = new Vue({
      methods: {
        show(message, timeout = 5000) {
          proto.$store.commit('SET_SNACKBAR', {
            isToggled: true,
            message,
            timeout,
          })
        },
        getIsToggled() {
          return proto.$store.getters.snackbar.isToggled
        },
        setIsToggled(toggled) {
          proto.$store.commit('SET_SNACKBAR_PROP_IS_TOGGLED', toggled)
        },
      },
    })
  },
}

export default Snackbar
