/**
 * Client store
 * @namespace Client
 */

/**
 * @memberof Client
 * @typedef {Object} Client
 * @property {string} uuid
 * @property {string} name
 */

/**
 * @memberof Client
 * @typedef {Object} ClientStore
 * @property {Client[]} data
 */

/**
 * @function ClientStoreCreator
 * @memberof Client
 * @returns {ClientStore}
 */

export const NAMESPACE = 'clients'

export default () => ({
  data: [],
  error: '',
})
