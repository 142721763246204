export function mapKeys(obj, namespace) {
  return Object.keys(obj).reduce((acc, key) => {
    let val = key

    if (namespace) {
      val = namespace + '/' + val
    }

    acc[key] = val
    return acc
  }, {})
}
