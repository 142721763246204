const Modal = {
  install(Vue) {
    const proto = Vue.prototype
    Vue.prototype.$modal = new Vue({
      methods: {
        open({ heading, bodyText, buttonProps }) {
          const isOpen = true
          proto.$store.commit('SET_MODAL', {
            isOpen,
            heading,
            bodyText,
            buttonProps,
          })
        },
        close() {
          proto.$store.commit('SET_MODAL_PROP_IS_OPEN', false)
        },
        getIsOpen() {
          return proto.$store.getters.modal.isOpen
        },
        setIsOpen(isOpen) {
          proto.$store.commit('SET_MODAL_PROP_IS_OPEN', isOpen)
        },
      },
    })
  },
}

export default Modal
