import vueEnv from '@/utils/env'

const CLIENT_ID = vueEnv.clientId
const ISSUER = vueEnv.issuer
const REDIRECT_URI = vueEnv.redirectUri
const MESSAGES_URL = vueEnv.messagesUrl
const GOOGLE_IDP = vueEnv.googleIdp

const DOMAIN = vueEnv.auth0Domain
const AUTH0_CLIENT_ID = vueEnv.auth0ClientId
const AUDIENCE = vueEnv.auth0Audience

export default {
  oidc: {
    clientId: CLIENT_ID,
    domain: ISSUER.split('/oauth2')[0],
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    testing: {
      disableHttpsCheck: false,
    },
    googleIdp: GOOGLE_IDP,
  },
  resourceServer: {
    messagesUrl: MESSAGES_URL,
  },
  auth0: {
    domain: DOMAIN,
    clientId: AUTH0_CLIENT_ID,
    audience: AUDIENCE,
  },
}
