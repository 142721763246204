import { mapKeys } from '@/store/helpers'

const mutations = {
  set(state, { data, links, meta }) {
    state.data = data
    state.links = links
    state.meta = meta
  },
  error(state, data) {
    state.error = data
  },
  preview(state, data) {
    state.previewed = data
  },
  add(state, data) {
    state.data = [...state.data, data]
  },
  update(state, data) {
    const opps = [...state.data]
    const index = opps.findIndex((opp) => opp.id === data.id)
    opps.splice(index, 1, data)
    state.data = opps
  },
}

export const keys = mapKeys(mutations)
export default mutations
