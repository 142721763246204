import axios from 'axios'
import authConfig from '@/config/auth'
import vueEnv from '@/utils/env'
import ResponseError from '@/utils/ResponseError'

const { clientId } = authConfig.oidc
const instance = axios.create({
  baseURL: vueEnv.onDemandApi,
  headers: {
    'Content-Type': 'application/json',
    'Okta-Client-Id': clientId,
  },
})

const Request = {
  install(Vue) {
    /**
     * Builds request function for MAPI or netlify functions
     */
    const requestFnBuilder = (axiosInstance) =>
      async function request(url, props = {}) {
        let {
          method = 'get',
          headers = {},
          params,
          data,
          timeout = 30000,
        } = props
        /**
         * According to the Okta documentation at this time (4/8/21) refresh
         * tokens aren't yet available for our implementation
         * https://developer.okta.com/docs/guides/refresh-tokens/overview/
         *
         * Once they are we'll be able to update this. In the mean time, what's happening
         * for issue MKT-695 is the window is being left open and the token is expiring, but
         * requests on timers are still being made. This will automatically redirect the user
         * to login when their token expires. Not perfect, but it should prevent users from
         * being confused why their page no longer updates.
         */

        const useAuth0 = await Vue.prototype.$auth0.isAuth0Enabled

        let accessToken
        try {
          accessToken = useAuth0
            ? await Vue.prototype.$auth0.getTokenSilently()
            : Vue.prototype.$auth.getAccessToken()
        } catch (e) {
          throw new ResponseError('Unauthorized', 401)
        }

        if (!accessToken) {
          throw new ResponseError('Unauthorized', 401)
        }
        if (
          !useAuth0 &&
          Vue.prototype.$auth.tokenManager.hasExpired(accessToken)
        ) {
          window.location = '/login'
          return
        }
        try {
          headers = {
            Authorization: 'Bearer ' + accessToken,
            ...headers,
          }
          const { data: responseData } = await axiosInstance({
            url,
            method,
            headers,
            params,
            data,
            timeout,
          })

          return responseData
        } catch (error) {
          let { name: title, messages } = error

          let status = 500

          if (error.response) {
            const {
              data: { title: responseTitle, messages: responseMessages },
              status: responseStatus,
            } = error.response

            title = responseTitle
            messages = responseMessages
            status = responseStatus
          }

          throw new ResponseError(title, status, messages)
        }
      }
    Vue.prototype.$request = requestFnBuilder(instance)
  },
}

export default Request
