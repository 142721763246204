<template>
  <v-snackbar
    v-model="show"
    class="toast"
    bottom
    left
    rounded="lg"
    :timeout="-1"
    transition="slide-x-transition"
  >
    <div class="py-4">
      <div class="d-flex flex-column flex-sm-row align-center">
        <slot />

        <div
          class="ml-sm-13 mt-4 mt-sm-0 d-flex flex-row-reverse flex-sm-column justify-end"
        >
          <v-btn outlined rounded class="mb-sm-2" :to="link" @click="close">
            View Details
          </v-btn>
          <v-btn plain @click="close"> Remind me later </v-btn>
        </div>
      </div>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: true,
    }
  },
  methods: {
    close() {
      this.$emit('close-toast')
    },
  },
}
</script>

<style lang="scss" scoped>
.toast ::v-deep .v-snack__wrapper {
  @include xsmall-only {
    border-radius: 0 !important;
    width: 100%;
    margin: 0;
  }
}
</style>
