<template>
  <button @click="logout">
    <slot> Sign Out </slot>
  </button>
</template>

<script>
import { FEATURE_FLAGS_CLEAR_STATE } from '@/store/feature-flags/mutation-types'

export default {
  name: 'Logout',
  methods: {
    async logout() {
      try {
        if (this.$auth0.isAuth0Enabled) {
          await this.$auth0.logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          })
        } else {
          await this.$auth.signOut({
            postLogoutRedirectUri:
              window.location.origin + '/login?success=true',
          })
        }
        this.$store.commit('featureFlags/' + FEATURE_FLAGS_CLEAR_STATE)
      } catch (error) {
        this.$dd.addError(error)
        this.$snackbar.show('Could not log out, please try again')
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
