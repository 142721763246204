import {
  CLEAR_CLINICIANS,
  CLEAR_CLINICIANS_ERROR,
  SET_CLINICIANS,
  SET_CLINICIANS_ERROR,
  SET_CLINICIANS_LOADING,
} from './mutation-types'

export const mutations = {
  /**
   * Clears the `clinicians` map.
   * @param {CliniciansStore} state
   */
  [CLEAR_CLINICIANS](state) {
    state.clinicians = []
  },

  /**
   * Sets the `error` state to an empty string.
   * @param {CliniciansStore} state
   */
  [CLEAR_CLINICIANS_ERROR](state) {
    state.error = ''
  },

  /**
   * Adds clinicians to the `clinicians` map.
   * @param {CliniciansStore} state
   * @param {Clinician[]} clinicians
   */
  [SET_CLINICIANS](state, clinicians) {
    state.clinicians = clinicians.filter(({ status }) => status === 'active')
  },

  /**
   * Sets the `error` state.
   * @param {CliniciansStore} state
   * @param {string} error
   */
  [SET_CLINICIANS_ERROR](state, error) {
    state.error = error
  },

  /**
   * Sets the `loading` state.
   * @param {CliniciansStore} state
   * @param {boolean} loading
   */
  [SET_CLINICIANS_LOADING](state, loading) {
    state.loading = loading
  },
}

export default mutations
