import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import MaterialIcon from '@/components/Icons/MaterialIcon.vue'

function missingMaterialIcons(ids) {
  const icons = {}
  for (const id of ids) {
    for (const suffix of ['fill', 'outline', 'two-tone', 'round', 'sharp']) {
      const name = `${id}_${suffix}`
      icons[name] = {
        component: MaterialIcon,
        props: {
          name,
        },
      }
    }
  }
  return icons
}

Vue.use(Vuetify)

export const lightTheme = {
  primary: '#2E4F25',
  secondary: '#424242',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
}

export default new Vuetify({
  theme: {
    themes: {
      light: lightTheme,
    },
  },
  icons: {
    values: {
      // https://vuetifyjs.com/en/features/icon-fonts/#missing-material-icons
      ...missingMaterialIcons(['edit']),
    },
  },
})
