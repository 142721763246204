/**
 * Opportunities store
 * @namespace Opportunities
 */

/**
 * @memberof Opportunities
 * @typedef {Object} Opportunity
 * @property {number} id
 */

/**
 * @memberof Opportunities
 * @typedef {Object} OpportunityStore
 * @property {Opportunity[]} data
 * @property {Object} links
 * @property {Object} meta
 * @property {String} error
 * @property {Boolean} previewed
 */

/**
 * @function OpportunityStoreCreator
 * @memberof Opportunities
 * @returns {OpportunityStore}
 */

export const NAMESPACE = 'opportunities'

export default () => ({
  data: [],
  links: {},
  meta: {},
  error: '',
  previewed: false,
})
