import * as types from './constants'

function getPausedUntil(seconds) {
  return Date.now() + seconds * 1000
}

export default {
  [types.CLINICIAN_SET](state, data) {
    data.paused_until = data.paused_for_seconds
      ? getPausedUntil(data.paused_for_seconds)
      : null
    delete data.paused_for_seconds

    state.clinician = {
      ...state.clinician,
      ...data,
    }
  },
  [types.CLINICIAN_SET_PAUSED_UNTIL]({ clinician }, seconds) {
    clinician.paused_until = seconds ? getPausedUntil(seconds) : null
  },
  [types.CLINICIAN_BREAK_SET]({ clinician }, data) {
    clinician.break = data
  },
}
