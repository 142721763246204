import {
  FeatureFlagClient,
  FeatureFlagService,
  LocalFeatureFlagClientImpl as LocalFFClient,
} from '@enzyme-health/feature-flags-client'
import vueEnv from '@/utils/env'

export const FEATURE_FLAGS = {
  SCHEDULED_SYNC_PREVIEW: 'scheduled_sync_preview',
  SKILLJAR: 'skilljar',
  AUTH0: 'auth0',
  CLINICIAN_METRICS: 'clinician_metrics',
}

export { LocalFFClient }

export default new FeatureFlagService(
  new FeatureFlagClient(`${vueEnv.onDemandApi}/internal`)
)
