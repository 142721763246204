import {
  TYPEFORM_ENQUEUE_FORM,
  TYPEFORM_CLEAR_QUEUE,
  TYPEFORM_ADVANCE_QUEUE,
  TYPEFORM_TOGGLE,
  TYPEFORM_SHOW,
  TYPEFORM_HIDE,
} from './mutation-types'

/**
 * @memberof Typeform
 * @typedef {Object} Form
 * @property {String} formId
 * @property {Object} data
 */

/**
 * @memberof Typeform
 * @typedef {Object} TypeformStore
 * @property {Boolean} isToggled
 * @property {Form[]} forms
 */

export const mutations = {
  /**
   * Update `Typeform` forms queue with new value.
   * @param {TypeformStore} state
   * @param {Form} form
   */
  [TYPEFORM_ENQUEUE_FORM](state, { form }) {
    state.forms.push(form)
  },

  /**
   * Advance `Typeform` forms queue.
   * @param {TypeformStore} state
   */
  [TYPEFORM_ADVANCE_QUEUE](state) {
    state.forms.shift()
  },

  /**
   * Clears `Typeform` queue and change isToggled to false.
   * @param {TypeformStore} state
   */
  [TYPEFORM_CLEAR_QUEUE](state) {
    state.forms = []
    state.isToggled = false
  },

  /**
   * Changes `Typeform` isToggled to the opposite state/boolean.
   * @param {TypeformStore} state
   */
  [TYPEFORM_TOGGLE](state) {
    state.isToggled = !state.isToggled
  },

  /**
   * Changes `Typeform` isToggled to true.
   * @param {TypeformStore} state
   */
  [TYPEFORM_SHOW](state) {
    state.isToggled = true
  },

  /**
   * Changes `Typeform` isToggled to false.
   * @param {TypeformStore} state
   */
  [TYPEFORM_HIDE](state) {
    state.isToggled = false
  },
}

export default mutations
