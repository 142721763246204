import state from './state'
import actions, { actionKeys, mappedActions } from './actions'
import getters, { mappedGetters } from './getters'
import mutations from './mutations'

export const typeformHelpers = {
  mappedActions,
  mappedGetters,
  actionKeys,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
