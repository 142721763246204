import Vue from 'vue'
import { appHelpers } from '@/store/app/index'
import { deserialize } from 'deserialize-json-api'

import { createNamespacedHelpers } from 'vuex'
import { mapKeys } from '@/store/helpers'
import { NAMESPACE } from './state'
import {
  SET_ERROR,
  SET_NOTIFY,
  SET_DATA_AND_META,
  SET_LOADING,
  SET_DRAFT_FEEDBACK,
  ADD_FEEDBACK,
  SET_UNRESOLVED_TOTAL,
  UPDATE_FEEDBACK,
  SET_VIEWABLE_REPORTS,
} from './mutation-types'

import FeedbackEntity from '../../entities/Feedback'

/**
 * @typedef {import('../index').ActionFunction} ActionFunction
 * @typedef {import('../index').ActionContext} ActionContext
 * @typedef {import('./state').Feedback} Feedback
 */

const { mapActions } = createNamespacedHelpers(NAMESPACE)

const actions = {
  async index(
    { commit, getters },
    { refresh = false, page = 1, pageSize = 20, status = null }
  ) {
    try {
      let response = getters.meta

      // If response has already been made and successfully stored, return unless refresh requested.
      if (Number.isInteger(response?.total) && !refresh) {
        return
      }

      commit(SET_ERROR, '')

      response = await Vue.prototype.$request('internal/clinician-feedbacks', {
        params: {
          offset: page > 1 ? (page - 1) * pageSize : null,
          limit: pageSize,
          status,
        },
      })

      const deserializedResponse = deserialize(response)

      commit(SET_DATA_AND_META, deserializedResponse)

      if (status === 'unresolved') {
        commit(SET_UNRESOLVED_TOTAL, {
          total: response.meta.total,
        })
      }

      return deserializedResponse
    } catch (error) {
      Vue.prototype.$dd?.addError(error)
      commit(SET_ERROR, error.message)
    }
  },
  async notify({ dispatch, getters, commit }, { id, notified_at, read_at }) {
    if (getters.notified || read_at) {
      return
    }

    dispatch(
      appHelpers.actionKeys.showToast,
      {
        component: 'FeedbackLinkToast',
        props: {
          link: `/feedback/${id}`,
        },
      },
      { root: true }
    )

    commit(SET_NOTIFY, true)

    // Only record first time clinician is notified.
    if (notified_at) {
      return
    }

    try {
      await Vue.prototype.$request(
        `internal/clinician-feedbacks/${id}/notify`,
        {
          method: 'patch',
        }
      )
    } catch (error) {
      Vue.prototype.$dd?.addError(error)
    }
  },

  /**
   * Retrieves feedback by ID from the server or the store if it's already available.
   * @type {function(ActionContext, string):Promise|undefined}
   *
   * @param {ActionContext} context - The action context
   * @param {string} id - The ID of the feedback to retrieve.
   *
   * @throws {Error} If an error occurs while retrieving the feedback.
   */
  async get({ getters, commit, rootState }, id) {
    try {
      const feedback = getters.feedback(id) || getters.viewableFeedback(id)
      const { uuid } = rootState.clinician

      if (!feedback) {
        commit(SET_LOADING, true)
        const response = await Vue.prototype.$request(
          `internal/clinician-feedbacks/${id}`
        )
        const { data } = deserialize(response)

        const isTargetClinician = uuid === data.clinician.uuid
        commit(ADD_FEEDBACK, { data, isTargetClinician })
      }
    } catch (error) {
      Vue.prototype.$dd?.addError(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },

  /**
   * Creates a single feedback item.
   *
   * @type {function(ActionContext, string):Promise|undefined}
   * @param {ActionContext} context - The action context.
   * @param {Object} params - Params for the create.
   * @param {Feedback} params.payload - The feedback fields to create an item with.
   * @param {boolean} params.report - If an additional request should be made to report feedback.
   *
   * @throws {Error} If an error occurs while creating the feedback.
   */
  async create(context, { payload, report }) {
    const { rootState, commit } = context

    try {
      const { uuid } = rootState.clinician

      commit(SET_LOADING, true)

      const response = await Vue.prototype.$request(
        'internal/clinician-feedbacks/',
        {
          method: 'post',
          data: payload.toDTO ? payload.toDTO() : payload,
        }
      )

      const { data } = deserialize(response)
      const isTargetClinician = uuid === data.clinician.uuid

      commit(ADD_FEEDBACK, { data, isTargetClinician })

      if (report) {
        await context.dispatch('update', {
          id: data.id,
          action: 'report',
        })
      }

      return data.id
    } catch (error) {
      Vue.prototype.$dd?.addError(error)
      Vue.prototype.$snackbar?.show('Feedback could not be created.')
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },

  /**
   * Updates a single feedback item by ID.
   *
   * @type {function(ActionContext, string):Promise|undefined}
   * @param {ActionContext} context - The action context
   * @param {Object} params - Params for the update.
   * @param {string} params.id - The ID of the feedback to update.
   * @param {string} params.action - The action to perform (e.g. "resolve").
   * @param {Feedback} params.payload - The updated feedback fields to update with.
   *
   * @throws {Error} If an error occurs while updating the feedback.
   */
  async update({ commit, rootState, dispatch }, { id, action, payload }) {
    try {
      let response
      let data
      const { uuid } = rootState.clinician

      commit(SET_LOADING, true)
      if (payload) {
        data = payload.toDTO ? payload.toDTO() : payload
      }

      if (action) {
        response = await Vue.prototype.$request(
          `internal/clinician-feedbacks/${id}/${action}`,
          {
            method: 'patch',
            data: action === 'report' && payload ? data : undefined,
          }
        )
      } else if (action === 'report') {
        response = await Vue.prototype.$request(
          `internal/clinician-feedbacks/${id}/report`,
          {
            method: 'patch',
          }
        )
      } else {
        response = await Vue.prototype.$request(
          `internal/clinician-feedbacks/${id}`,
          {
            method: 'patch',
            data,
          }
        )
      }
      const { data: feedback } = deserialize(response)

      const isTargetClinician = uuid === feedback.clinician.uuid
      commit(UPDATE_FEEDBACK, { data: feedback, isTargetClinician })

      if (!isTargetClinician) {
        dispatch('updateDraftFeedback', { feedback })
      }
    } catch (error) {
      Vue.prototype.$dd?.addError(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },

  async delete({ commit }, { id }) {
    try {
      commit(SET_LOADING, true)

      await Vue.prototype.$request(`internal/clinician-feedbacks/${id}`, {
        method: 'delete',
      })

      commit(UPDATE_FEEDBACK, { data: {} })
    } catch (error) {
      Vue.prototype.$dd?.addError(error)

      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },

  /**
   * Updates the draft feedback property on the Vuex store.
   *
   * @type {function(ActionContext, string):undefined}
   *
   * @param {ActionContext} context - The action context
   * @param {FeedbackEntity} feedback - Params for the update.
   */
  updateDraftFeedback({ commit }, feedback = null) {
    commit(SET_DRAFT_FEEDBACK, feedback)
  },

  /**
   * @typedef SearchParams
   * @property {string} limit - The size of the results being returned.
   * @property {string} search - Search string to filter by Clinician's Name.
   * @property {number} offset - Cursor to start the info fetching from.
   */

  /**
   * Fetches the given Feedback the Clinician Champion has sent.
   *
   * @type {function(ActionContext, SearchParams):Promise<FeedbackStore['meta']>}
   *
   * @param {ActionContext} context - The action context
   * @param {SearchParams} params - Params for searching and limiting.
   * @returns {Promise<FeedbackStore['meta']>} - The meta data for the request.
   */
  async getFeedbackReports(
    { commit },
    { limit = 20, search = null, offset = null, filters = null }
  ) {
    try {
      commit(SET_ERROR, '')
      commit(SET_LOADING, true)

      const response = await Vue.prototype.$request(
        'internal/clinician-feedbacks/reports',
        {
          params: {
            limit,
            search,
            offset,
            ...filters,
          },
        }
      )

      const { data, meta } = deserialize(response)

      const reports = data.map((report) => FeedbackEntity.fromJSON(report))

      commit(SET_VIEWABLE_REPORTS, { reports })

      return { meta }
    } catch (error) {
      Vue.prototype.$dd?.addError(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
}

export const mappedActions = mapActions(Object.keys(actions))
export const actionKeys = mapKeys(actions, NAMESPACE)
export default actions
