import { mapKeys } from '@/store/helpers'

const mutations = {
  addToNotifications(state, data) {
    const allNotifications = [...state.notifications, ...data]
    const uniqueNotifications = allNotifications.reduce(
      (notifs, current) => {
        if (!notifs.seen[current.id]) {
          notifs.seen[current.id] = true
          notifs.unique.push(current)
        }
        return notifs
      },
      { seen: {}, unique: [] }
    ).unique

    state.notifications = uniqueNotifications
  },
  setNotificationError(state, error) {
    state.error = error
  },
  setNotificationLoading(state, loading) {
    state.loading = loading
  },
  removeFromNotifications(state, notification_id) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== notification_id
    )
  },
  /**
   * Add notification data to the Vuex store.
   * Notification data is added to the 'browserNotifications' section of the store
   *
   * @param {NotificationsStore} state - The current Notifications store state.
   * @param {Object} data - An object containing the notification to add to browserNotifications and the notification type
   *
   * @returns {void}
   */
  addToBrowserNotifications(state, data) {
    state.browserNotifications = [...state.browserNotifications, data]
  },
  /**
   * Clear all notifications of the specified type from the 'browserNotifications' section of the Vuex store.
   *
   * @param {NotificationsStore} state - The current Dashboard store state.
   * @param {string} type - A string indicating the type of notification to clear from the store.
   *
   * @returns {void}
   */
  clearBrowserNotifications(state, type) {
    state.browserNotifications = state.browserNotifications.filter((n) => {
      if (n.type === type) n.notification.close()
      return n.type !== type
    })
  },
}

export const keys = mapKeys(mutations)
export default mutations
