<template>
  <a
    v-if="link"
    class="support-link text-decoration-underline"
    :href="href"
    @click="activateZendesk"
  >
    <slot>contact support</slot>
  </a>
  <v-btn
    v-else
    rounded
    elevation="0"
    outlined
    class="support-link"
    :href="href"
    @click="activateZendesk"
  >
    <slot>Contact Support</slot>
  </v-btn>
</template>

<script>
import Zendesk from '@/mixins/Zendesk'
import ResponseError from '@/utils/ResponseError'

/**
 * Generates a search param string to append to a `mailto` link.
 *
 * @param { ResponseError | null } error - The `error` prop.
 *
 * @returns { String } searchParamString - A string to append to a `mailto` link.
 *
 * @example
 * ```javascript
 * generateEmailProps(new ResponseError('Server issue, please try again later', 500)) // "subject=Support+Request+-+Server+issue%2C+please+try+again+later+-+Status%3A+500&body=Hello%2C%0D%0A%0D%0A ..."
 * ```
 */
export const generateEmailProps = (error) => {
  let subject = 'Support Request'
  let body =
    '[Please enter anything that may be helpful to resolve your issue.]'

  if (error) {
    const greeting =
      "Hello,\n\nI'm experiencing an error when I try to [please input what page you're trying to access or action you're trying to make].\n\n"
    subject += ' - ' + error.message
    body = greeting + body + '\n\n' + error.toString()
  }

  const emailProps = new URLSearchParams({ subject, body })

  // Replace all newlines with a properly-escaped CRLF char
  // See https://stackoverflow.com/a/27447222
  return emailProps.toString().replaceAll('%0A', '%0D%0A')
}

export default {
  name: 'SupportLink',
  mixins: [Zendesk],
  props: {
    /** Renders an inline anchor link. */
    link: {
      type: Boolean,
      default: false,
    },

    /** Error info we wish to pipe into a support email or Zendesk ticket. */
    error: {
      type: ResponseError,
      default: null,
    },
  },
  data() {
    return {
      supportEmail: 'support@wheel.com',
    }
  },
  computed: {
    href() {
      return `mailto:${this.supportEmail}?${generateEmailProps(this.error)}`
    },
  },
  async created() {
    try {
      const clinician = await this.$clinician.getProfileData()

      this.initializeZendesk(clinician)
    } catch (err) {
      this.$dd?.addError(err)
      this.initializeZendesk(null)
    }
  },
  methods: {
    activateZendesk(e) {
      if (!this.$zendesk?.enabled || !this.$zendesk?.activate) {
        return
      }

      e.preventDefault()

      // TODO: pass error props into `activate` as values for defined ticket fields.
      this.$zendesk.activate()
    },
  },
}
</script>

<style lang="scss" scoped>
.support-link {
  color: $green;
}

.v-btn {
  // HACK: These styles could be removed after a styling refresh
  font-family: 'Apercu Bold', 'Apercu Regular', Helvetica, Arial, sans-serif;
  letter-spacing: 0.2px;
}
</style>
