import { keys as mutations } from './mutations'
import { appHelpers } from '@/store/app/index'
import { dayjs } from '@/utils/Date'

import { createNamespacedHelpers } from 'vuex'
import { mapKeys } from '@/store/helpers'
import { NAMESPACE } from './state'

const actions = {
  async index({ commit, getters }, refresh = false) {
    try {
      let response = getters.opportunities

      if (response.length && !refresh) {
        return
      }

      commit(mutations.error, '')
      response = await this._vm.$request('internal/opportunities')
      commit(mutations.set, response)

      return response
    } catch (error) {
      this._vm.$dd?.addError(error)
      commit(mutations.error, error.message)
    }
  },
  async preview({ dispatch, getters, commit }, { client, id }) {
    if (getters.previewed) {
      return
    }

    dispatch(
      appHelpers.actionKeys.showToast,
      {
        component: 'OpportunityLinkToast',
        props: {
          clientName: client.name,
          link: `/opportunities/${id}`,
        },
      },
      { root: true }
    )

    commit(mutations.preview, true)

    try {
      this._vm.$request(`internal/opportunities/${id}`, {
        method: 'patch',
        data: {
          previewed_at: dayjs().toISOString(),
        },
      })
    } catch (error) {
      /* noop */
    }
  },
  async get({ getters, commit }, id) {
    try {
      let response = getters.opportunity(id)

      if (!response || !response.description) {
        const mutation = response ? mutations.update : mutations.add
        response = await this._vm.$request(`internal/opportunities/${id}`)
        commit(mutation, response)
      }

      return response
    } catch (error) {
      this._vm.$dd?.addError(error)
      throw error
    }
  },
  async update({ commit }, { id, ...props }) {
    try {
      let response = await this._vm.$request(`internal/opportunities/${id}`, {
        method: 'patch',
        data: props.data,
      })
      commit(mutations.update, response)

      return response
    } catch (error) {
      this._vm.$dd?.addError(error)
      throw error
    }
  },
}

const { mapActions } = createNamespacedHelpers(NAMESPACE)
export const mappedActions = mapActions(Object.keys(actions))
export const actionKeys = mapKeys(actions, NAMESPACE)
export default actions
