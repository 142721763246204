<template>
  <basic-link-toast :link="link" v-on="$listeners">
    <client-label-icon :client-name="clientName" :tooltip="false">
      <template #title>
        <div role="alert">New Client Opportunity</div>
      </template>

      <template>
        <div class="h6">
          {{ clientName }}
        </div>
      </template>
    </client-label-icon>
  </basic-link-toast>
</template>

<script>
import BasicLinkToast from './BasicLinkToast.vue'
import ClientLabelIcon from '@/components/Icons/ClientLabelIcon.vue'

export default {
  components: {
    BasicLinkToast,
    ClientLabelIcon,
  },
  props: {
    clientName: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
}
</script>
