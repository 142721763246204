import { keys as mutations } from './mutations'

import { createNamespacedHelpers } from 'vuex'
import { mapKeys } from '@/store/helpers'
import { NAMESPACE } from './state'
import { getAllItems } from '@/utils/Pagination'

const { mapActions } = createNamespacedHelpers(NAMESPACE)

const actions = {
  async index({ getters, commit }, path = 'internal/clients/historical') {
    try {
      let response

      if (getters.clients?.length) {
        await getAllItems(path).then((data) => {
          response = data
          commit(mutations.set, response)
        })
      } else {
        response = await getAllItems(path)
      }
      commit(mutations.set, response)

      return response
    } catch (error) {
      commit(mutations.error, error.message)
      this._vm.$dd?.addError(error)
    }
  },
  async get({ getters, commit }, id) {
    try {
      let response = getters.clients(id)

      if (!response) {
        response = await this._vm.$request(`internal/clients/${id}`)
        commit(mutations.add, response.data)
      }

      return response
    } catch (error) {
      this._vm.$dd?.addError(error)
      throw error
    }
  },
}

export const mappedActions = mapActions(Object.keys(actions))
export const actionKeys = mapKeys(actions, NAMESPACE)
export default actions
