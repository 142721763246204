export const NAMESPACE = 'clinicians'
export const METRIC_TYPES = {
  SCHEDULED_SYNC_ATTENDANCE_RATE: 'scheduled_sync_attendance_rate',
  SCHEDULED_SYNC_PUNCTUALITY_RATE: 'scheduled_sync_punctuality_rate',
  ACCEPTANCE_RATE: 'acceptance_rate',
  SCHEDULED_SHIFT_HOURS: 'scheduled_shift_hours',
}
export const METRIC_COUNT_UNIT_TYPES = {
  WEEK: 'week',
  CONSULT: 'consult',
}
export const METRIC_VALUE_UNIT_TYPES = {
  HOURS: 'hours',
  PERCENT: 'percent',
}
