import { createNamespacedHelpers } from 'vuex'
import { mapKeys } from '@/store/helpers'
import {
  TYPEFORM_ENQUEUE_FORM,
  TYPEFORM_CLEAR_QUEUE,
  TYPEFORM_ADVANCE_QUEUE,
  TYPEFORM_HIDE,
  TYPEFORM_SHOW,
} from './mutation-types'
import { NAMESPACE } from './constants'

export const actions = {
  enqueueForm({ commit, getters }, options) {
    let forms = getters.getTypeformForms
    if (forms.some((i) => i.formId === options.formId)) {
      // There is already a form in state with that `formId`
      return
    }

    const newForm = {
      formId: options.formId,
      data: options.data,
    }
    commit(TYPEFORM_ENQUEUE_FORM, { form: newForm })
    commit(TYPEFORM_SHOW)
  },

  advanceQueue({ commit, getters }) {
    let forms = getters.getTypeformForms
    if (forms.length > 1) {
      commit(TYPEFORM_HIDE)
      commit(TYPEFORM_ADVANCE_QUEUE)
      commit(TYPEFORM_SHOW)
    } else {
      commit(TYPEFORM_CLEAR_QUEUE)
    }
  },
}

const { mapActions } = createNamespacedHelpers(NAMESPACE)
export const mappedActions = mapActions(Object.keys(actions))
export const actionKeys = mapKeys(actions, NAMESPACE)
export default actions
