import { createNamespacedHelpers } from 'vuex'
import { NAMESPACE } from './constants'
const { mapGetters } = createNamespacedHelpers(NAMESPACE)

export const getters = {
  /**
   * Returns true if the given feature flag is enabled, otherwise false.
   */
  isFeatureEnabled: (state) => (flagName) => !!state.featureFlags[flagName],
  isFeatureLoading: (state) => (flagName) =>
    !!state.featureFlagsLoading[flagName],

  /**
   * Returns the current error state.
   */
  getError: (state) => state.error,
}

export const mappedGetters = mapGetters(Object.keys(getters))
export default getters
