import { mapKeys } from '@/store/helpers'

const mutations = {
  addToCurrent(state, data) {
    state.data = [...state.data, data]
  },
  addToFuture(state, data) {
    state.upcoming = [...state.upcoming, data]
  },
  accept(_state, data) {
    data.status = 'accepted'
    data.expires_at = null
  },
  flag(state, data) {
    state.flaggedAssignment = {
      ...state.flaggedAssignment,
      ...data,
    }

    if (data.alert && data.assignment) {
      state.flaggedAssignment.assignment.potential_issues = true
    }
  },
  setCurrent(state, data) {
    state.data = data
  },
  setSaved(state, data) {
    state.saved = data
  },
  setReadyForResponse(state, data) {
    state.readyForResponse = data
  },
  setCount(state, { type, count }) {
    state[`${type}Count`] = count
  },
  setFuture(state, data) {
    state.upcoming = data
  },
}

export const keys = mapKeys(mutations)
export default mutations
