import { mapKeys } from '@/store/helpers'
import { SET_HIDDEN } from '@/store/dashboard/mutation-types'

const mutations = {
  /**
   * Set the hidden property of the Vuex store state.
   *
   * @param {DashboardStore} state - The current Dashboard store state.
   * @param {Boolean} data - A flag indicating whether the dashboard is hidden.
   *
   * @returns {void}
   */
  [SET_HIDDEN](state, data) {
    state.hidden = data
  },
}

export const keys = mapKeys(mutations)
export default mutations
