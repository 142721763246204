export default class ResponseError extends Error {
  /**
   * @param {string} title - A useful title for this error.
   * @param {number} status - An HTTP status code.
   * @param {string|string[]} messages - An array of error response messages returned by the server. Default empty array.
   */
  constructor(title, status, messages = []) {
    super(`${title} - Status: ${status}`)
    this.title = title
    this.status = status
    this.messages = Array.isArray(messages) ? messages : [messages]
  }

  get name() {
    return this.constructor.name
  }

  toString() {
    return `${this.constructor.name}: ${this.message} ${JSON.stringify(
      this,
      null,
      2
    )}`
  }
}
