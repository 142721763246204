import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { NAMESPACE } from './constants'
import {
  CLEAR_CLINICIANS_ERROR,
  SET_CLINICIANS,
  SET_CLINICIANS_ERROR,
  SET_CLINICIANS_LOADING,
} from './mutation-types'

const { mapActions } = createNamespacedHelpers(NAMESPACE)

export const actions = {
  /**
   * Fetches all clinicians given a set of search params.
   * @promise
   * @param {{
   *    commit: () => void
   * }}
   * @param {{
   *    params: {
   *      search?: string;
   *    }
   * }} payload
   */
  async searchClinicians({ commit }, params) {
    commit(SET_CLINICIANS_LOADING, true)
    try {
      const response = await Vue.prototype.$request('internal/peers', {
        params,
      })

      if (response?.data?.length) {
        commit(SET_CLINICIANS, response.data)
      }
      commit(CLEAR_CLINICIANS_ERROR)
    } catch (e) {
      commit(SET_CLINICIANS_ERROR, e?.message ?? e)
    } finally {
      commit(SET_CLINICIANS_LOADING, false)
    }
  },
}

export const mappedActions = mapActions(Object.keys(actions))
export default actions
