import { mapKeys } from '@/store/helpers'

const mutations = {
  updateAnnouncements(state, num) {
    const value = Math.max(state.announcements + num, 0)
    state.announcements = value
  },
  toggleDrawer(state) {
    state.drawerOpen = !state.drawerOpen
  },
  closeDrawer(state) {
    state.drawerOpen = false
  },
}

export const keys = mapKeys(mutations)
export default mutations
