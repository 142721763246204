import actions, { mappedActions } from './actions'
import getters, { mappedGetters } from './getters'
import mutations from './mutations'
import state from './state'

export const cliniciansHelpers = {
  mappedActions,
  mappedGetters,
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
