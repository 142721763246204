import Vue from 'vue'
import FeedbackEntity from '../../entities/Feedback'
import {
  SET_ERROR,
  SET_NOTIFY,
  SET_DATA_AND_META,
  SET_LOADING,
  SET_DRAFT_FEEDBACK,
  ADD_FEEDBACK,
  SET_UNRESOLVED_TOTAL,
  UPDATE_FEEDBACK,
  SET_VIEWABLE_REPORTS,
} from './mutation-types'

/**
 * @typedef {import('./state').FeedbackStore} FeedbackStore
 * @typedef {import('./state').Feedback} Feedback
 * @typedef {import('./state').FeedbackEntity} FeedbackEntity
 */

const mutations = {
  /**
   * Set the data and meta properties of the Vuex store state.
   *
   * @param {FeedbackStore} state - The current Feedback store state.
   * @param {Object} payload - An object containing the 'data' and 'meta' properties to be set.
   * @param {Feedback[]} payload.data - An array of feedback data to be set in the store.
   * @param {Object} payload.meta - An object containing metadata about the feedback data.
   *
   * @returns {void}
   */
  [SET_DATA_AND_META](state, { data, meta }) {
    state.targetedFeedback = data
    state.meta = meta
  },

  /**
   * Set the error property of the Vuex store state.
   *
   * @param {FeedbackStore} state - The current Feedback store state.
   * @param {string} errorString - An error string to be set in the store.
   *
   * @returns {void}
   */
  [SET_ERROR](state, errorString) {
    state.error = errorString
  },

  /**
   * Set the notified property of the Vuex store state.
   *
   * @param {FeedbackStore} state - The current Feedback store state.
   * @param {Boolean} notified - A flag indicating whether the user has been notified.
   *
   * @returns {void}
   */
  [SET_NOTIFY](state, notified) {
    state.notified = notified
  },

  /**
   * Add feedback data to the Vuex store. The feedback data is added to the
   * 'data' section of the store if it is targeted toward the clinician.
   *
   * @param {FeedbackStore} state - The current Feedback store state.
   * @param {Object} payload - Payload containing feedback data
   * @param {Feedback} payload.data - The feedback data to add to the store.
   * @param {Boolean} payload.isTargetClinician - A flag indicating whether the feedback is targeted toward the clinician.
   * If true, the feedback is added to the 'targetedFeedback' section of the store; otherwise it is added to 'viewableFeedback'
   *
   * @returns {void}
   */
  [ADD_FEEDBACK](state, { data, isTargetClinician }) {
    if (isTargetClinician) {
      state.targetedFeedback = [...state.targetedFeedback, data]
    } else {
      Vue.set(state.viewableFeedback, data.id, FeedbackEntity.fromJSON(data))
    }
  },

  /**
   * Update the feedback data in the Feedback store.
   *
   * @param {FeedbackStore} state - The current Feedback store state.
   * @param {Feedback} data - The updated feedback data to set in the store.
   * @param {Boolean} isTargetClinician - A flag indicating whether the feedback is targeted toward the clinician.
   * If true, the feedback is added to the 'targetedFeedback' section of the store; otherwise it is added to 'viewableFeedback'
   *
   * @returns {void}
   */
  [UPDATE_FEEDBACK](state, { data, isTargetClinician }) {
    if (isTargetClinician) {
      const allFeedback = [...state.targetedFeedback]
      const index = allFeedback.findIndex((feedback) => feedback.id === data.id)
      allFeedback.splice(index, 1, data)
      state.targetedFeedback = allFeedback
    } else {
      Vue.set(state.viewableFeedback, data.id, FeedbackEntity.fromJSON(data))
    }
  },

  /**
   * Set the unresolvedCount property of the Vuex store state.
   *
   * @param {FeedbackStore} state - The current Feedback store state.
   * @param {Object} payload - An object containing the 'total' property to be set.
   * @param {Number} payload.total - The total number of unresolved feedback items.
   *
   * @returns {void}
   */
  [SET_UNRESOLVED_TOTAL](state, { total }) {
    state.unresolvedCount = total
  },

  /**
   * Set the draftFeedback property of the Vuex store state.
   *
   * @param {FeedbackStore} state - The current Feedback store state.
   * @param {FeedbackEntity} feedback - The feedback object to set on the store
   *
   * @returns {void}
   */
  [SET_DRAFT_FEEDBACK](state, feedback) {
    state.draftFeedback = feedback
  },

  /**
   * Set the loading property of the Vuex store state.
   *
   * @param {FeedbackStore} state - The current Feedback store state.
   * @param {Boolean} loading - A flag indicating whether the store is currently loading data.
   *
   * @returns {void}
   */
  [SET_LOADING](state, loading) {
    state.loading = loading
  },

  /**
   * Set the reported feedback the Clinician Champion has access to.
   *
   * @param {FeedbackStore} state - The current Feedback store state.
   * @param {Array} reports - The array containing the viewable feedback for Clinician Champions
   *
   * @returns {void}
   */

  [SET_VIEWABLE_REPORTS](state, { reports }) {
    state.viewableFeedback = {}

    reports.forEach((report) => {
      Vue.set(state.viewableFeedback, report.id, report)
    })
  },
}

export default mutations
