import { createNamespacedHelpers } from 'vuex'
import { NAMESPACE } from './state'
import { mapKeys } from '@/store/helpers'
const { mapGetters } = createNamespacedHelpers(NAMESPACE)

function syncFirstSort(assignmentA, assignmentB) {
  const modalityA = assignmentA.consult.consult_rate.modality_type
  const modalityB = assignmentB.consult.consult_rate.modality_type

  if (modalityA === 'sync' && modalityB !== 'sync') {
    return -1
  }

  if (modalityB === 'sync' && modalityA !== 'sync') {
    return 1
  }

  return 0
}

const getters = {
  current({ data }) {
    return data
  },
  future({ upcoming }) {
    return upcoming.sort((a, b) => {
      return (
        new Date(a.consult?.appointment?.buffer_start_at) -
        new Date(b.consult?.appointment?.buffer_start_at)
      )
    })
  },
  upcoming(_state, getters) {
    const newAssignments = getters.current
      .filter(({ status }) => status === 'new')
      .sort(syncFirstSort)

    const assignedConsults = [...newAssignments, ...getters.future]

    return assignedConsults
  },
  readyForResponse({ readyForResponse }) {
    return readyForResponse
  },
  saved({ saved }) {
    return saved
  },
  assignment: (state) => (id) => {
    return state.data.find((assignment) => assignment.id === id)
  },
  accepted: (_state, getters) => (type) => {
    return getters[type].filter(
      (assignment) => assignment.status === 'accepted'
    )
  },
  count: (state) => (type) => {
    return state[`${type}Count`]
  },
  flagged({ flaggedAssignment }) {
    return flaggedAssignment
  },
}

export const mappedGetters = mapGetters(Object.keys(getters))
export const getterKeys = mapKeys(getters, NAMESPACE)
export default getters
