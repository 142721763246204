import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import FeatureFlagService from '@/services/FeatureFlag'
import {
  FEATURE_FLAGS_SET_LOADING,
  FEATURE_FLAGS_SET_FLAG,
} from './mutation-types'
import { NAMESPACE } from './constants'

const { mapActions } = createNamespacedHelpers(NAMESPACE)

/**
 * Returns an access token's payload if it exists.
 * Throws an error if the user's token is invalid or missing.
 * @async
 * @returns {Promise<{
 *   clinician_id?: string;
 * }>} payload - The user's claims object.
 */
export const getAccessTokenPayload = async () => {
  const proto = Vue.prototype
  const authToken = await proto.$auth.getAccessToken()

  if (!authToken) {
    throw new Error(
      'Invalid or missing authorization token. Please log out and log in again.'
    )
  }

  return proto.$auth.token.decode(authToken)?.payload ?? {}
}

export const actions = {
  async fetchFeatureFlagForUser(context, { flagName }) {
    if (Vue.prototype.$auth0.isAuth0Enabled) {
      return actions.fetchFeatureFlagForAuth0User(context, { flagName })
    }
    return actions.fetchFeatureFlagForOktaUser(context, { flagName })
  },

  async fetchFeatureFlagForAuth0User(context, { flagName }) {
    const authToken = await Vue.prototype.$auth0.getTokenSilently()
    const userId = Vue.prototype.$auth0.clinicianId
    await actions.fetchFeatureFlag(context, {
      flagName,
      userId,
      authToken,
    })
  },

  /**
   * Fetches a single feature flag config for a signed-in user.
   * @promise
   * @param {{
   *    commit: () => void
   * }}
   * @param {{
   *    flagName: string;
   * }} payload
   */
  async fetchFeatureFlagForOktaUser({ commit }, { flagName }) {
    const proto = Vue.prototype
    const { clinician_id } = await getAccessTokenPayload()
    const authToken = await proto.$auth.getAccessToken()

    await actions.fetchFeatureFlag(
      { commit },
      { flagName, userId: clinician_id, authToken }
    )
  },

  async fetchFeatureFlag({ commit }, options) {
    const proto = Vue.prototype
    try {
      commit(FEATURE_FLAGS_SET_LOADING, {
        flagName: options.flagName,
        loading: true,
      })
      const response = await FeatureFlagService.isFeatureEnabled(options)

      commit(FEATURE_FLAGS_SET_FLAG, {
        flagName: options.flagName,
        isEnabled: response[options.flagName],
      })
    } catch (err) {
      const message = `Unable to fetch feature flag '${
        options.flagName
      }' for clinician '${options.userId || 'anonymous'}': ${
        err?.message ?? err
      }`
      proto.$dd.addError({ ...err, message })
    }

    commit(FEATURE_FLAGS_SET_LOADING, {
      flagName: options.flagName,
      loading: false,
    })
  },
}

export const mappedActions = mapActions(Object.keys(actions))
export default actions
