import { keys as mutations } from './mutations'
import { createNamespacedHelpers } from 'vuex'
import { mapKeys } from '@/store/helpers'
import { NAMESPACE } from './state'
import { notificationHelpers } from '@/store/notifications'
import { EVENT_TYPES } from '@/constants/pusher-event-types'

const notificationActions = notificationHelpers.actionKeys

// TODO: Handle errors appropriately instead of returning
const actions = {
  async loadCurrent({ commit, dispatch }) {
    try {
      const { data } = await this._vm.$request('internal/assignments/current')
      const assignmentWithPotentialIssues = data.find(
        (assignment) => assignment.potential_issues
      )

      dispatch('flag', {
        assignment: assignmentWithPotentialIssues || null,
        alert: !!assignmentWithPotentialIssues,
      })
      commit(mutations.setCurrent, data)
    } catch (e) {
      this._vm.$dd?.addError(e)
      return e
    }
  },
  removeFromCurrent({ commit, getters, dispatch }, { id }) {
    const reduced = getters.current.filter((assignment) => assignment.id !== id)

    if (getters.flagged.id === id) {
      dispatch('flag', {
        assignment: null,
        alert: false,
      })
    }
    commit(mutations.setCurrent, reduced)
  },
  async loadReadyForResponse({ commit }) {
    try {
      const { data } = await this._vm.$request(
        'internal/assignments/ready_for_response'
      )
      commit(mutations.setReadyForResponse, data)
    } catch (e) {
      this._vm.$dd?.addError(e)
      return e
    }
  },
  async loadSaved({ commit }) {
    try {
      const { data } = await this._vm.$request('internal/assignments/saved')
      commit(mutations.setSaved, data)
    } catch (e) {
      this._vm.$dd?.addError(e)
      return e
    }
  },
  addToCurrent({ commit, getters, dispatch }, data) {
    const assignment = getters.assignment(data.id)
    if (assignment) {
      return
    }

    commit(mutations.addToCurrent, data)
    dispatch(
      notificationActions.addToBrowserNotifications,
      { type: EVENT_TYPES.NEW_ASSIGNMENT },
      { root: true }
    )
    dispatch('removeFromFuture', data.consult)
  },
  accept({ commit, getters }, data) {
    const assignment = getters.assignment(data.id)

    if (!assignment) {
      return
    }

    commit(mutations.accept, assignment)
  },
  async loadByStatus(_store, { status, page }) {
    try {
      const { data, ...rest } = await this._vm.$request(
        `internal/assignments/${status}`,
        {
          params: { page },
        }
      )
      return {
        data: data.sort(
          (a, b) => new Date(a.created_at) <= new Date(b.created_at)
        ),
        ...rest,
      }
    } catch (e) {
      this._vm.$dd?.addError(e)
      return e
    }
  },
  async loadStatusCount({ commit }, status) {
    try {
      // Change page_size request parameter to be non-zero if using for endpoints other than held/deferred
      const {
        meta: { total },
      } = await this._vm.$request(`internal/assignments/${status}?page_size=0`)
      commit(mutations.setCount, { type: status, count: total })
    } catch (e) {
      this._vm.$dd?.addError(e)
      return e
    }
  },
  async getFuture({ commit }) {
    try {
      const { data } = await this._vm.$request('internal/assignments/upcoming')
      commit(
        mutations.setFuture,
        data.map((booking) => ({ booking: true, consult: booking }))
      )
    } catch (e) {
      this._vm.$dd?.addError(e)
      return e
    }
  },
  addToFuture({ commit, getters }, consult) {
    const duplicateBooking = getters.future.find(
      (booking) => booking.consult.id === consult.id
    )

    if (duplicateBooking) {
      return
    }

    commit(mutations.addToFuture, { booking: true, consult })
  },
  removeFromFuture({ commit, getters }, { id }) {
    const reduced = getters.future.filter(
      (assignment) => assignment.consult.id !== id
    )
    commit(mutations.setFuture, reduced)
  },
  flag({ commit }, data) {
    commit(mutations.flag, data)
  },
  async decline(_store, declineLink) {
    return await this._vm.$request(`assignments/${declineLink}?noredirect=1`)
  },
  async acceptAsync(_store, acceptLink) {
    return await this._vm.$request(`assignments/${acceptLink}?noredirect=1`)
  },
  async sendConsultIssue({ dispatch }, { id, type, note, subcategory = null }) {
    if (type === 'Patient No Show') {
      return dispatch('markPatientNoShow', { id, note })
    }

    let data = {
      activity_type: type,
      metadata: { note },
    }

    if (subcategory) data = { ...data, subcategory }

    return await this._vm.$request(`internal/assignments/${id}/activities`, {
      method: 'post',
      data,
    })
  },
  async markPatientNoShow(_store, { id, description: note }) {
    try {
      await this._vm.$request(`internal/assignments/${id}/activities`, {
        method: 'post',
        data: { activity_type: 'patient_no_show', metadata: { note } },
      })

      this._vm.$snackbar.show('Consult marked as patient no show.')
    } catch (e) {
      this._vm.$dd?.addError(e)
      this._vm.$snackbar.show('Failed to mark the consult as patient no show')
    }
  },
}

const { mapActions } = createNamespacedHelpers(NAMESPACE)
export const mappedActions = mapActions(Object.keys(actions))
export const actionKeys = mapKeys(actions, NAMESPACE)
export default actions
