import Vue from 'vue'
import { keys as mutations } from './mutations'
import { createNamespacedHelpers } from 'vuex'
import { mapKeys } from '@/store/helpers'
import { NAMESPACE } from './constants'
import { EVENT_TYPES } from '@/constants/pusher-event-types'

const actions = {
  async loadNotifications({ commit }) {
    commit(mutations.setNotificationLoading, true)
    try {
      const data = await Vue.prototype.$request(
        'internal/notifications/?is_acknowledged=false'
      )
      if (data != null) {
        commit(mutations.addToNotifications, data)
      }
    } catch (e) {
      commit(mutations.setNotificationError, e?.message ?? e)
    } finally {
      commit(mutations.setNotificationLoading, false)
    }
  },
  async acknowledgeNotificationById({ commit }, notification_id) {
    try {
      await Vue.prototype.$request(
        `internal/notifications/${notification_id}/acknowledge`,
        { method: 'patch' }
      )
      commit(mutations.removeFromNotifications, notification_id)
    } catch (e) {
      commit(mutations.setNotificationError, e?.message ?? e)
    }
  },
  addToBrowserNotifications({ commit, rootGetters }, data) {
    const dashboardHidden = rootGetters['dashboard/getHidden']

    if (!dashboardHidden || Notification.permission !== 'granted') return

    commit(mutations.clearBrowserNotifications, data.type)
    let title, body
    switch (data.type) {
      case EVENT_TYPES.NEW_ASSIGNMENT:
        title = 'New Consults Available!'
        body =
          'You have new consult requests, please refer to clinician dashboard for details'
        break
      case EVENT_TYPES.CONSULT_BOOKING_REMINDER:
        title = 'Consult Booking Reminder'
        body =
          'You have a booked consult coming up, please refer to clinician dashboard for details'
        break
      case EVENT_TYPES.IDENTIFICATION_RESUBMITTED:
        title = 'Patient Identification Resubmitted'
        body = `A patient from ${data.client} has corrected their identification photos, please see ${data.consult_url} for details`
        break
      case EVENT_TYPES.STARTED_NOT_COMPLETE_WARNING:
        title = 'Consult Not Completed'
        body = `Looks like you've started consult ${data.partial_consult_id} but forgot to complete it, please see ${data.consult_url} for details`
        break
      default:
        return
    }
    const notification = new Notification(title, {
      body,
      icon: 'https://on-demand-public.s3.amazonaws.com/partner-icons/wheel.png',
    })

    commit(mutations.addToBrowserNotifications, {
      notification: notification,
      type: data.type,
    })
  },
  clearBrowserNotifications({ commit }, type) {
    commit(mutations.clearBrowserNotifications, type)
  },
}

const { mapActions } = createNamespacedHelpers(NAMESPACE)
export const mappedActions = mapActions(Object.keys(actions))
export const actionKeys = mapKeys(actions, NAMESPACE)
export default actions
