import { createNamespacedHelpers } from 'vuex'
import { NAMESPACE } from './state'
const { mapGetters } = createNamespacedHelpers(NAMESPACE)

const getters = {
  opportunities({ data }) {
    return data
  },
  new({ data }) {
    return data.filter((opp) => !opp.viewed_at)
  },
  newCount(_, getters) {
    return getters.new.length
  },
  previewed({ previewed }) {
    return previewed
  },
  opportunity: (store) => (id) => {
    return store.data.find((opp) => opp.id === id)
  },
}

export const mappedGetters = mapGetters(Object.keys(getters))
export default getters
