import Vue from 'vue'
import Vuex from 'vuex'
import state from '@/store/state'
import definedGetters from '@/store/getters'
import customMutations from '@/store/mutations'
import deepmerge from 'deepmerge'

// Modules
import app from '@/store/app'
import assignments from '@/store/assignments'
import opportunities from '@/store/opportunities'
import feedback from '@/store/feedback'
import clients from '@/store/clients'
import clinicians from '@/store/clinicians'
import featureFlags from '@/store/feature-flags'
import typeform from '@/store/typeform'
import notifications from '@/store/notifications'
import reporters from '@/store/reporters'
import dashboard from '@/store/dashboard'

/**
 * @typedef {Object} ActionContext
 * @property {Object} state - The local state of the module.
 * @property {Object} rootState - The global state of the store.
 * @property {Function} commit - The function to commit a mutation.
 * @property {Function} dispatch - The function to dispatch an action.
 * @property {Object} getters - The local module's getters.
 * @property {Object} rootGetters - The global store's getters.
 */

Vue.use(Vuex)

const storeKeys = Object.keys(state)

/**
 * Generates mutations for base level domain store and outer most object properties. The convention it follows for
 * naming is based on Redux / Vuex style "enums" (all caps snake case consts). So a base store mutation becomes
 * "SET_[STORE_NAME]" and "SET_[STORE_NAME]_PROP_[PROPERTY_NAME]"
 *
 * @type {Object} - an object where all keys map to a function
 */
const mutations = storeKeys.reduce((acc, storeName) => {
  const toSnakeAndUpperCase = (s) =>
    s.replace(/[A-Z]/g, (subString) => `_${subString}`).toUpperCase()
  const setterLabel = `SET_${toSnakeAndUpperCase(storeName)}`

  // note: general setter for entire [domain specific] store state
  acc[setterLabel] = function (state, data) {
    Vue.set(state, storeName, data)
  }

  // note: setters for individual properties within a [domain specific] store
  const storePropKeys = Object.keys(state[storeName])
  storePropKeys.forEach((propertyName) => {
    const propertySetterLabel = `SET_${toSnakeAndUpperCase(
      storeName
    )}_PROP_${toSnakeAndUpperCase(propertyName)}`

    acc[propertySetterLabel] = function (state, data) {
      // state[storeName][propertyName] = data
      Vue.set(state[storeName], propertyName, data)
    }
  })

  if (customMutations[storeName]) {
    Object.assign(acc, customMutations[storeName])
  }

  return acc
}, {})

const getters = {
  ...storeKeys.reduce((acc, storeName) => {
    acc[storeName] = (state) => state[storeName]
    return acc
  }, {}),
  ...definedGetters,
}

export const storeConfig = {
  modules: {
    app: deepmerge(app, {}),
    assignments: deepmerge(assignments, {}),
    opportunities: deepmerge(opportunities, {}),
    feedback: deepmerge(feedback, {}),
    clients: deepmerge(clients, {}),
    clinicians: deepmerge(clinicians, {}),
    featureFlags: deepmerge(featureFlags, {}),
    typeform: deepmerge(typeform, {}),
    notifications: deepmerge(notifications, {}),
    reporters,
    dashboard: deepmerge(dashboard, {}),
  },
  state,
  getters,
  mutations,
}

export default new Vuex.Store(deepmerge(storeConfig, {}))
