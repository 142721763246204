/**
 * Typeform store
 * @namespace Typeform
 */

/**
 * @memberof Typeform
 * @typedef {Object} Form
 * @property {String} formId
 * @property {Object} data
 */

/**
 * @memberof Typeform
 * @typedef {Object} TypeformStore
 * @property {Boolean} isToggled
 * @property {Form[]} forms
 */

/**
 * @function TypeformStoreCreator
 * @memberof Typeform
 * @returns {TypeformStore}
 */
const TypeformStoreCreator = () => ({
  isToggled: false,
  forms: [],
})

export default TypeformStoreCreator
