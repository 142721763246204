<template>
  <div class="snackbar__container">
    <v-btn
      v-if="showButton"
      class="demo-button"
      dark
      @click="() => toggle(true)"
    >
      Open Snackbar
    </v-btn>
    <v-snackbar v-model="isToggled" class="snackbar" :timeout="timeout">
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
import { VSnackbar } from 'vuetify/lib'
import { mapState } from 'vuex'

export default {
  name: 'Snackbar',
  components: {
    VSnackbar,
  },
  props: {
    showButton: Boolean,
  },
  computed: {
    ...mapState({
      message: ({ snackbar }) => snackbar.message,
      timeout: ({ snackbar }) => snackbar.timeout,
    }),
    isToggled: {
      get: function () {
        return this.$snackbar.getIsToggled()
      },
      set: function (value) {
        this.$snackbar.setIsToggled(value)
      },
    },
  },
  methods: {
    toggle(value) {
      this.$snackbar.setIsToggled(value)
    },
  },
}
</script>
