import axios from 'axios'

const Zendesk = {
  methods: {
    async initializeZendesk(clinician) {
      if (!this.$zendesk?.enabled) {
        return
      }

      if (clinician) {
        const payload = {
          name: clinician.name,
          email: clinician.email,
        }

        const token = await this.getZendeskToken(payload)
        this.$zendesk.initialize({
          webWidget: {
            authenticate: {
              jwtFn: function (callback) {
                callback(token)
              },
            },
            contactForm: {
              fields: [
                {
                  id: 'name',
                  prefill: {
                    '*': clinician.name,
                  },
                },
                {
                  id: 'email',
                  prefill: {
                    '*': clinician.email,
                  },
                },
              ],
            },
          },
        })
      } else {
        this.$zendesk.initialize()
      }
    },
    async getZendeskToken(payload) {
      try {
        const { data } = await axios({
          method: 'post',
          url: '/.netlify/functions/zendesk',
          data: JSON.stringify({ payload }),
        })
        return data.token
      } catch (e) {
        // ignore
      }
    },
  },
}

export default Zendesk
