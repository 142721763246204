import * as types from './mutation-types'
import Vue from 'vue'

export default {
  async index({ commit }) {
    commit(types.SET_LOADING, true)
    try {
      const data = await Vue.prototype.$request(
        'internal/clinician-feedbacks/reporters'
      )
      commit(types.SET_REPORTERS, data)
    } catch (e) {
      commit(types.SET_ERROR, e.message)
    }
    commit(types.SET_LOADING, false)
  },
}
