import axios from 'axios'

const Skilljar = {
  install(Vue) {
    Vue.prototype.$skilljar = new Vue({
      methods: {
        async getEnrollments({ userId, email }) {
          const useAuth0 = await Vue.prototype.$auth0.isAuth0Enabled
          const token = useAuth0
            ? await Vue.prototype.$auth0.getTokenSilently()
            : Vue.prototype.$auth.getAccessToken()

          return callNetlifyFunction('skilljar_enrollments', token, {
            userId,
            email,
          })
        },
        async getCourses() {
          const useAuth0 = Vue.prototype.$auth0.isAuth0Enabled
          const token = useAuth0
            ? await Vue.prototype.$auth0.getTokenSilently()
            : Vue.prototype.$auth.getAccessToken()
          return callNetlifyFunction('skilljar_courses', token)
        },
      },
    })
  },
}

const callNetlifyFunction = (functionName, token, params) => {
  return axios({
    method: 'get',
    url: `/.netlify/functions/${functionName}`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export default Skilljar
