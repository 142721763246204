const vueEnv = {
  issuer: import.meta.env.VITE_ISSUER,
  version: import.meta.env.VITE_APP_VERSION,
  clientId: import.meta.env.VITE_CLIENT_ID,
  featureFlagServiceApi: import.meta.env.VITE_FEATURE_FLAGS_SERVICE_API,
  googleIdp: import.meta.env.VITE_GOOGLE_IDP,
  messagesUrl: import.meta.env.VITE_MESSAGES_URL,
  onDemandApi: import.meta.env.VITE_ON_DEMAND_API,
  pusherAppKey: import.meta.env.VITE_PUSHER_APP_KEY,
  redirectUri: import.meta.env.VITE_REDIRECT_URI,
  oldStripeClientId: import.meta.env.VITE_OLD_STRIPE_CLIENT_ID,
  stripeClientId: import.meta.env.VITE_STRIPE_CLIENT_ID,
  windowedConsultClients: import.meta.env.VITE_WINDOWED_CONSULT_CLIENTS,
  resourcesUrl: import.meta.env.VITE_RESOURCES_URL,
  auth0Audience: import.meta.env.VITE_AUTH0_AUDIENCE,
  auth0Domain: import.meta.env.VITE_AUTH0_DOMAIN,
  auth0ClientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  statsigClientId: import.meta.env.VITE_STATSIG_CLIENT_ID,
  beamerProductId: import.meta.env.VITE_BEAMER_PRODUCT_ID,
  ddApplicationId: import.meta.env.VITE_DD_APPLICATION_ID,
  ddClientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
  ddEnv: import.meta.env.VITE_DD_ENV,
  emrLinks: import.meta.env.VITE_EMR_LINKS,
  enableFeedback: import.meta.env.VITE_ENABLE_FEEDBACK,
  enableTaxBanner: import.meta.env.VITE_ENABLE_TAX_BANNER,
  skilljarHipaaCourseId: import.meta.env.VITE_SKILLJAR_HIPAA_COURSE_ID,
  skillJarIntroCourseIds: import.meta.env.VITE_SKILLJAR_INTRO_COURSE_IDS,
  zendeskKey: import.meta.env.VITE_ZENDESK_KEY,
  startCountdown: import.meta.env.VITE_START_COUNTDOWN,
  bufferedStartClients: import.meta.env.VITE_BUFFERED_START_CLIENTS,
}

export default vueEnv
