/**
 * @typedef {import('../clinicians/state').Clinician} Clinician
 * @typedef {import('../clients/state').Client} Client
 */

/**
 * Feedback store
 * @namespace Feedback
 */

/**
 * @memberof Feedback
 * @typedef {Object} Feedback
 * @property {number} id
 */

/**
 * @memberof Feedback
 * @typedef {Object} FeedbackEntity
 * @property {number} id
 * @property {string} type
 * @property {string} category
 * @property {string} subCategory
 * @property {string[]} consultUrls
 * @property {string} description
 * @property {string[]} referenceUrls
 * @property {string} status
 * @property {boolean} notifyClinician
 * @property {string|undefined} level
 * @property {Date|undefined|null} reportedAt
 * @property {Date|undefined|null} notifiedAt
 * @property {Date|undefined|null} readAt
 * @property {Date|undefined|null} resolvedAt
 * @property {string|undefined} resolvedBy
 * @property {string|undefined} clientUUID
 * @property {Client|undefined} client
 * @property {string|undefined} clinicianUUID
 * @property {Clinician|undefined} clinician
 */

/**
 * @memberof Feedback
 * @typedef {Object} FeedbackStore
 * @property {Feedback[]} targetedFeedback
 * @property {FeedbackEntity} draftFeedback
 * @property {Object.<string, FeedbackEntity>} viewableFeedback
 * @property {Integer} unresolvedCount
 * @property {Object} meta
 * @property {String} error
 * @property {Boolean} loading
 * @property {Boolean} notified
 */

/**
 * @function FeedbackStoreCreator
 * @memberof Feedback
 * @returns {FeedbackStore}
 */

export const NAMESPACE = 'feedback'

export default () => ({
  targetedFeedback: [],
  unresolvedCount: 0,
  draftFeedback: {},
  viewableFeedback: {},
  meta: {},
  error: '',
  loading: false,
  notified: false,
})
