import {
  FEATURE_FLAGS_CLEAR_ERROR,
  FEATURE_FLAGS_CLEAR_STATE,
  FEATURE_FLAGS_SET_ERROR,
  FEATURE_FLAGS_SET_FLAG,
  FEATURE_FLAGS_SET_LOADING,
} from './mutation-types'

/**
 * @memberof FeatureFlags
 * @typedef {Object} FeatureFlagsStore
 * @property {FeatureFlagData} featureFlags
 * @property {String} error
 */

export const mutations = {
  [FEATURE_FLAGS_SET_LOADING](state, { flagName, loading }) {
    state.featureFlagsLoading = {
      ...state.featureFlagsLoading,
      [flagName]: loading,
    }
  },
  /**
   * Sets a feature flag config in the state.
   * @param {FeatureFlagsStore} state
   * @param {{
   *    flagName: string;
   *    isEnabled: boolean;
   * }} featureFlags
   */
  [FEATURE_FLAGS_SET_FLAG](state, { flagName, isEnabled }) {
    state.featureFlags = {
      ...state.featureFlags,
      [flagName]: isEnabled,
    }
  },

  /**
   * Clears `FeatureFlags` store.
   * @param {FeatureFlags.featureFlags} state
   */
  [FEATURE_FLAGS_CLEAR_STATE](state) {
    state.featureFlags = {}
    state.featureFlagsLoading = {}
    state.error = ''
  },

  /**
   * Sets an error on the feature flags state.
   * @param {FeatureFlagsStore} state
   * @param {string} error
   */
  [FEATURE_FLAGS_SET_ERROR](state, error) {
    state.error = error
  },

  /**
   * Clears an error on the feature flags state.
   * @param {FeatureFlagsStore} state
   */
  [FEATURE_FLAGS_CLEAR_ERROR](state) {
    state.error = ''
  },
}

export default mutations
