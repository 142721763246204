export default {
  about: null,
  available: false,
  certification_summary: null,
  clinician_preferences: {
    preferred_consult_types: [],
    preferred_days: [],
    preferred_hours: null,
    preferred_times: [],
  },
  break: null,
  collab_agreements: [],
  education_summary: null,
  email: null,
  experience_summary: null,
  id: null,
  interests: null,
  languages: [],
  license_info: {},
  licenses: [],
  name: null,
  npi: null,
  on_shift: false,
  paused_until: null,
  populations: [],
  practice_start_at: null,
  practitioner_type: null,
  race: [],
  specialties: [],
  status: null,
  stripe_id: null,
  timezone: null,
  title: null,
  treatment_methods: [],
  updated_at: null,
  uuid: null,
}
