const Earnings = {
  install(Vue) {
    const proto = Vue.prototype
    Vue.prototype.$earnings = new Vue({
      methods: {
        open() {
          proto.$store.commit('SET_EARNINGS_PROP_IS_OPEN', true)
        },
        close() {
          proto.$store.commit('SET_EARNINGS_PROP_IS_OPEN', false)
        },
        getIsOpen() {
          return proto.$store.getters.modal.isOpen
        },
        setIsOpen(isOpen) {
          proto.$store.commit('SET_EARNINGS_PROP_IS_OPEN', isOpen)
        },
      },
    })
  },
}

export default Earnings
