<template>
  <v-app>
    <toast-container />
    <snackbar />
    <drawer class="d-lg-none d-md-flex" :toggle="drawer" />
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import debounce from 'debounce'
import Snackbar from '@/components/Global/Snackbar.vue'
import ToastContainer from '@/components/Toast/ToastContainer.vue'
import Drawer from '@/components/Nav/Drawer.vue'
import { appHelpers as app } from '@/store/app'

const TITLE = 'Wheel Clinician Dashboard'

export default {
  name: 'App',
  components: {
    Snackbar,
    ToastContainer,
    Drawer,
  },
  metaInfo: {
    titleTemplate: `%s - ${TITLE}`,
  },
  computed: {
    ...mapState({
      authenticated: ({ clinician }) => !!clinician.id,
    }),
    drawer: app.mappedGetters.drawerOpen,
  },
  watch: {
    authenticated() {
      this.closeDrawer()
    },
  },
  mounted() {
    window.onresize = this.setDrawer
  },
  methods: {
    closeDrawer: app.mappedActions.closeDrawer,
    setDrawer: debounce(function () {
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.closeDrawer()
      }
    }, 1000),
  },
}
</script>
