import { createNamespacedHelpers } from 'vuex'
import { mapKeys } from '@/store/helpers'
import { NAMESPACE } from './state'
import { SET_HIDDEN } from '@/store/dashboard/mutation-types'

const actions = {
  setHidden({ commit }, data) {
    commit(SET_HIDDEN, data)
  },
}

const { mapActions } = createNamespacedHelpers(NAMESPACE)
export const mappedActions = mapActions(Object.keys(actions))
export const actionKeys = mapKeys(actions, NAMESPACE)
export default actions
