import { appHelpers as app } from '@/store/app/index'
import vueEnv from '@/utils/env'

export default {
  install: function install(Vue) {
    const productId = vueEnv.beamerProductId
    let enabled = !!productId
    let initialized = false

    if (enabled) {
      window.beamer_config = {
        lazy: true,
        button: false,
        top: 2,
        right: -22,
        selector: '.beamer-announcements',
        product_id: productId,
      }

      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.defer = true
      script.id = 'beamer-snippet'
      script.src = 'https://app.getbeamer.com/js/beamer-embed.js'

      document.body.appendChild(script)
    }

    Vue.prototype.$beamer = new Vue({
      data() {
        return {
          enabled,
        }
      },
      methods: {
        updateAnnouncements: app.mappedActions.updateAnnouncementsCount,
        initialize(clinician) {
          if (!this.enabled || initialized) {
            return
          }

          try {
            const [firstName, lastName] = clinician.name.split(' ', 1)

            window.Beamer.update({
              user_firstname: firstName,
              user_lastname: lastName,
              user_email: clinician.email,
              user_id: clinician.id,
              callback: (posts) => this.updateAnnouncements(posts),
              onopen: (posts) => this.updateAnnouncements(posts),
            })

            window.Beamer.init()
            initialized = true
          } catch (e) {
            // ignore
          }
        },
      },
    })
  },
}
