import axios from 'axios'
import vueEnv from '@/utils/env'

const DEFAULT_ERROR = 'There was an unknown error'

const Stripe = {
  install(Vue) {
    Vue.prototype.$stripe = new Vue({
      methods: {
        async authorizeStripeAccount(code) {
          if (!code) {
            throw new Error('Could not authorize stripe.')
          }

          await Vue.prototype.$request('internal/earnings/stripe', {
            method: 'post',
            data: {
              code,
            },
          })
        },
        createStripeConnectUrl(user) {
          if (!user.id) {
            return ''
          }

          const baseUrl = 'https://connect.stripe.com/express/oauth/authorize'
          const [firstName, ...lastName] = user.name.split(' ')
          const params = {
            client_id: vueEnv.stripeClientId,
            redirect_uri: window.location.origin + '/stripe/authorize',
            'stripe_user[email]': user.email,
            'stripe_user[first_name]': firstName,
            'stripe_user[last_name]': lastName.join(' '),
          }

          const querystring = new URLSearchParams(params)
          querystring.append('suggested_capabilities[]', 'transfers')
          querystring.append(
            'suggested_capabilities[]',
            'tax_reporting_us_1099_misc'
          )

          return `${baseUrl}?${querystring}`
        },
        async getStripeDashboardUrl(accountId, type = 'new') {
          try {
            const { data } = await axios({
              method: 'post',
              url: `/.netlify/functions/stripe_connect?type=${type}`,
              data: JSON.stringify({ accountId }),
            })

            return data.url
          } catch (e) {
            let error = e.response || e.request || {}

            const { data = DEFAULT_ERROR } = error

            throw new Error(data)
          }
        },
      },
    })
  },
}

export default Stripe
