export default {
  isOpen: false,
  summary: [],
  summaryMonth: {
    data: [],
    total: 0,
  },
  filterOptions: {
    clients: [],
  },
  history: {
    data: [],
    links: {},
    meta: {},
  },
}
