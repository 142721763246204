import { createNamespacedHelpers } from 'vuex'
import { NAMESPACE } from './state'
const { mapGetters } = createNamespacedHelpers(NAMESPACE)

/**
 * @typedef {import('./state').FeedbackStore} FeedbackStore
 * @typedef {import('./state').Feedback} Feedback
 */

const findFeedbackById = (id) => (feedback) =>
  Number(feedback.id) === Number(id)

const getters = {
  /**
   * Returns an array of all targeted feedback objects reported to the clinician in the feedback store.
   * @param {FeedbackStore} state - The feedback store.
   * @returns {Feedback[]} An array of feedback objects.
   */
  allFeedback: ({ targetedFeedback }) => {
    return targetedFeedback
  },

  /**
   * Returns the metadata object of the feedback store.
   * @param {FeedbackStore} state - The feedback store.
   * @returns {Object} The metadata object.
   */
  meta: ({ meta }) => {
    return meta
  },

  /**
   * Returns the number of unresolved feedback objects in the feedback store.
   * @param {FeedbackStore} state - The feedback store.
   * @returns {number} The number of unresolved feedback objects.
   */
  unresolvedCount: ({ unresolvedCount }) => {
    return unresolvedCount
  },

  /**
   * Returns a boolean value indicating whether the user has been notified of new feedback.
   * @param {FeedbackStore} state - The feedback store.
   * @returns {boolean} A boolean value indicating whether the user has been notified of new feedback.
   */
  notified: ({ notified }) => {
    return notified
  },

  /**
   * Returns a function that takes a feedback ID and returns the corresponding feedback object, or undefined if not found.
   * The function searches the `targetedFeedback` array for the given ID.
   * @param {FeedbackStore} store - The feedback store.
   * @returns {function(number): Feedback|undefined} A function that takes a feedback ID and returns the corresponding feedback object, or undefined if not found.
   */
  feedback: (store) => (id) => {
    return store.targetedFeedback.find(findFeedbackById(id))
  },

  /**
   * Returns a function that takes a feedback ID and returns the corresponding feedback object, or undefined if not found.
   * The function searches the `viewableFeedback` map for the given ID.
   * @param {FeedbackStore} store - The feedback store.
   * @returns {function(number): Feedback|undefined} A function that takes a feedback ID and returns the corresponding feedback object, or undefined if not found.
   */
  viewableFeedback: (store) => (id) => {
    return store.viewableFeedback[id]
  },

  /**
   * Returns a function that takes a sort order and returns a list of viewable feedback objects sorted by ID.
   * @param {FeedbackStore} store - The feedback store.
   * @returns {function(string): Feedback[]} A function that takes a sort order and returns a list of viewable feedback objects sorted by ID.
   */
  viewableFeedbackList:
    (store) =>
    (sortOrder = 'DESC') => {
      const feedbackList = Object.values(store.viewableFeedback)
      return sortOrder === 'DESC'
        ? feedbackList.sort((a, b) => b.id - a.id)
        : feedbackList.sort((a, b) => a.id - b.id)
    },
}

export const mappedGetters = mapGetters(Object.keys(getters))
export default getters
