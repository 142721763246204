import actions, { mappedActions, actionKeys } from './actions'
import getters, { mappedGetters, getterKeys } from './getters'
import mutations from './mutations'
import state from './state'

export const notificationHelpers = {
  actionKeys,
  mappedActions,
  getterKeys,
  mappedGetters,
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
