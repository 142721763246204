import Vue from 'vue'

/**
 * `getCurrentPageStartElement` returns the start element of the current page.
 * Note that this returns the 1-based index of the starting element, not the element itself.
 * @param {number} currentPage
 * @param {number} pageSize
 * @example getCurrentPageStartElement(1, 20); // 1
 * @example getCurrentPageStartElement(2, 20); // 21
 */
export const getCurrentPageStartElement = (currentPage, pageSize) =>
  currentPage === 1 ? 1 : (currentPage - 1) * pageSize + 1

/**
 * `getCurrentPageEndElement` returns the end element of the current page.
 * Note that this returns the 1-based index of the starting element, not the element itself.
 * @param {number} currentPage
 * @param {number} totalPages
 * @param {number} pageSize
 * @param {number} totalElementsCount
 * @example getCurrentPageEndElement(1, 2, 10, 9); // 9
 * @example getCurrentPageEndElement(2, 2, 10, 19); // 19
 */
export const getCurrentPageEndElement = (
  currentPage,
  totalPages,
  pageSize,
  totalElementsCount
) => {
  if (currentPage === totalPages) return totalElementsCount
  return currentPage * pageSize
}

/**
 * `getAllItems` returns all items from an endpoint, in batches of 100 at a time.
 * @param {string} url
 * @example getAllItems('internal/clients');
 */
export const getAllItems = async (url) => {
  let response = await Vue.prototype.$request(url, { params: { items: 100 } })
  let results = response.data
  while (response.links?.next) {
    response = await Vue.prototype.$request(response.links.next, {
      params: { items: 100 },
    })
    results = results.concat(response.data)
  }
  response.data = results
  return response
}
