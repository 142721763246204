import { getInstance } from '../plugins/auth0'

export const authGuard = (to, from, next) => {
  const authService = getInstance()

  const fn = () => {
    if (!authService.isAuth0Enabled || authService.isAuthenticated) {
      // If the user is authenticated or okta is the auth tool of choice, continue with the route
      return next()
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
  }

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.isPluginLoading) {
    return fn()
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('isPluginLoading', (loading) => {
    if (loading === false) {
      return fn()
    }
  })
}
