/**
 * Notifications store
 * @namespace Notifications
 */

/**
 * Notifications Model
 * @memberof Notifications
 * @typedef {Object} Notification
 * @property {number} id
 * @property {string} message
 */

/**
 * BrowserNotifications Model
 * @memberof Notifications
 * @typedef {Object} BrowserNotification
 * @property {Object} notification
 * @property {string} type
 */

/**
 * @memberof Notifications
 * @typedef {Object} NotificationsStore
 * @property {Notification[]} notifications
 * @property {BrowserNotification[]} browserNotifications
 * @property {String} error
 * @property {boolean} loading
 */

/**
 * @function NotificationsStoreCreator
 * @memberof Notifications
 * @returns {NotificationsStore}
 */

const NotificationsStoreCreator = () => ({
  notifications: [],
  error: '',
  loading: false,
  browserNotifications: [],
})

export default NotificationsStoreCreator
