import { mapKeys } from '@/store/helpers'

const mutations = {
  set(state, { data }) {
    state.data = data
  },
  add(state, data) {
    state.data = [...state.data, data]
  },
  error(state, data) {
    state.error = data
  },
}

export const keys = mapKeys(mutations)
export default mutations
