import { createNamespacedHelpers } from 'vuex'
import { NAMESPACE } from './constants'
import { mapKeys } from '@/store/helpers'
const { mapGetters } = createNamespacedHelpers(NAMESPACE)

const getters = {
  notifications({ notifications }) {
    return notifications
  },
  /**
   * Returns an array containing all the browserNotifications in the notifications store.
   * @param {NotificationsStore} state - The dashboard store.
   * @returns {array} The array containing the browserNotifications in the notifications store.
   */
  browserNotifications({ browserNotifications }) {
    return browserNotifications
  },
}

export const mappedGetters = mapGetters(Object.keys(getters))
export const getterKeys = mapKeys(getters, NAMESPACE)
export default getters
