import axios from 'axios'

const Airtable = {
  install(Vue) {
    Vue.prototype.$airtable = new Vue({
      methods: {
        async getEducationalMaterial() {
          const useAuth0 = Vue.prototype.$auth0.isAuth0Enabled
          const token = useAuth0
            ? await Vue.prototype.$auth0.getTokenSilently()
            : Vue.prototype.$auth.getAccessToken()
          return axios({
            method: 'get',
            url: '/.netlify/functions/educational_material',
            headers: {
              Authorization: 'Bearer ' + token,
            },
          })
        },
      },
    })
  },
}

export default Airtable
