import snackbar from './snackbar'
import clinician from './clinician'
import earnings from './earnings'
import bookings from './bookings'
import coverages from './coverages'

export default {
  snackbar,
  clinician,
  earnings,
  bookings,
  coverages,
}
