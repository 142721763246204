<!-- eslint-disable vue/no-v-html -->
<template>
  <article
    class="error-container d-flex flex-column justify-center align-center align-content-center"
  >
    <div class="image">
      <img
        v-if="errorConfig.image"
        :src="errorConfig.image"
        alt="An error image"
      />
    </div>
    <div class="header">
      <p class="status">
        {{ errorStatus }}
      </p>
      <h3>
        {{ errorConfig.errorTitle }}
      </h3>
    </div>
    <div
      v-if="errorConfig.remedies.length > 0"
      class="remedies d-flex flex-column justify-center align-center align-content-center"
    >
      <p class="font-weight-bold">Potential remedy:</p>
      <ul>
        <template v-for="remedy in errorConfig.remedies">
          <li :key="remedy" v-html="remedy" />
        </template>
      </ul>
    </div>
    <div
      class="support d-flex flex-column justify-center align-center align-content-center text-center"
    >
      <p>
        If you experience this error more than once or need additional help,
        please reach out to support for help.
      </p>
      <support-link />
    </div>
  </article>
</template>

<script>
import SupportLink from '@/components/SupportLink.vue'
import ResponseError from '@/utils/ResponseError'
import ErrorImage401 from '@/assets/images/error/401@2x.png'
import ErrorImage403 from '@/assets/images/error/403@2x.png'
import ErrorImage404 from '@/assets/images/error/404@2x.png'
import ErrorImage500 from '@/assets/images/error/500@2x.png'

const hardRefresh =
  'Try a "hard refresh". It may be that you&apos;ve been logged in for a long time and your authorization has timed out.<ul><li>Windows users: hold down Ctrl and then press F5 on your keyboard.</li><li>Mac users: hold down Cmd and Shift and then press R on your keyboard.</li></ul>'
const logoutAndBackIn =
  'Try logging out and logging back in if you think that you should have access to this page.'
const pageNotFound =
  "We can&apos;t find the page you&apos;re looking for. Please check the URL and try again, or go back to the <a href='/'>home page</a>."
const serverError = `Our support team has been notified and will look into this issue. In the meantime:
                      <ul>
                        <li>
                          Try refreshing the page. Sometimes a server becomes temporarily overloaded and another attempt is successful.
                        </li>
                        <li>
                          Wait a short while before attempting again. If a server is overloaded with traffic, it may take a short while to level out again.
                        </li>
                      </ul>`

export default {
  name: 'ErrorContainer',
  components: {
    SupportLink,
  },
  props: {
    error: {
      type: ResponseError,
      required: true,
    },
  },
  data() {
    return {
      mappedStatuses: {
        401: {
          errorTitle: 'Something went wrong',
          remedies: [hardRefresh],
          image: ErrorImage401,
        },
        403: {
          errorTitle: 'Oops! Looks like we can’t find you',
          remedies: [logoutAndBackIn],
          image: ErrorImage403,
        },
        404: {
          errorTitle: "Oops! Looks like that page doesn't exist",
          remedies: [pageNotFound],
          image: ErrorImage404,
        },
        '5xx': {
          errorTitle: 'Oops! Looks like something broke',
          remedies: [serverError],
          image: ErrorImage500,
        },
        fallback: {
          errorTitle: 'Something went wrong',
          remedies: [],
          image: ErrorImage500,
        },
      },
      development: import.meta.env.DEV,
    }
  },
  computed: {
    errorConfig() {
      if (this.error.status >= 500) return this.mappedStatuses['5xx']

      return (
        this.mappedStatuses[this.error?.status] ??
        this.mappedStatuses['fallback']
      )
    },
    errorStatus() {
      let errorStatusMessage = 'Unknown Error'

      if (this.error.status) {
        errorStatusMessage = `Error ${this.error.status}`
      }

      const errorDescription = this.error.title || this.error.message
      if (!errorDescription) return errorStatusMessage
      return `${errorStatusMessage} - ${errorDescription}`
    },
  },
  mounted() {
    if (this.development) {
      console.error(this.error)
    }
  },
}
</script>

<style lang="scss" scoped>
article.error-container {
  height: 100%;
  row-gap: 24px;

  > * {
    max-width: 500px;
  }

  > .image {
    min-height: 144px; // avoid jumping after the image loads
    max-width: 144px;

    > img {
      width: 100%;
    }
  }

  .header {
    > p.status {
      color: $green;
      text-align: center;

      font-family: 'Roboto Mono', 'Monaco', 'Courier New', Courier, monospace;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;
    }

    > h3 {
      color: $black;
      text-align: center;

      font-family: 'Ogg Bold', Times, 'Times New Roman', serif;
      font-size: 32px;
      font-style: normal;
      line-height: 125%; /* 40px */
    }
  }

  > p.subtitle {
    font-size: 24px;
    margin-bottom: 32px;
  }

  > .remedies {
    border-radius: 8px;
    border: 2px dashed $grey-500;
    padding: 12px 20px;

    > ul {
      list-style: none;
      padding-left: 0;
    }
  }

  > .support {
    margin-bottom: 32px;
  }
}
</style>
