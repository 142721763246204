import { datadogRum } from '@datadog/browser-rum'
import vueEnv from '@/utils/env'

const DataDog = {
  install(Vue) {
    const ddEnv = vueEnv.ddEnv
    const tracingOrigin = vueEnv.onDemandApi.split('/v1')[0]
    const applicationId = vueEnv.ddApplicationId
    const clientToken = vueEnv.ddClientToken
    const version = vueEnv.version

    if (ddEnv) {
      datadogRum.init({
        applicationId,
        allowedTracingOrigins: [tracingOrigin],
        clientToken,
        site: 'datadoghq.com',
        service: 'clinician-experience',
        env: ddEnv,
        sampleRate: 100,
        version,
        trackViewsManually: true,
        trackInteractions: false,
      })
    }

    const startView = (path) => {
      if (ddEnv) {
        datadogRum.startView(path)
      }
    }

    const setUser = (id) => {
      if (ddEnv) {
        datadogRum.setUser({ id })
      }
    }

    const addError = (error, context) => {
      if (ddEnv) {
        datadogRum.addError(error, context)
      }
    }

    const log = (message, level, data = {}) => {
      if (ddEnv) {
        datadogRum.addAction(level, {
          message,
          ...data,
        })
      }
    }

    Vue.prototype.$dd = {
      startView,
      setUser,
      addError,
      log,
    }
  },
}

export default DataDog
