import { createNamespacedHelpers } from 'vuex'
import { NAMESPACE } from './constants'
const { mapGetters } = createNamespacedHelpers(NAMESPACE)

/**
 * @memberof Clinicians
 * @typedef {Object} CliniciansStore
 * @property {Clinician[]} clinicians
 * @property {String} error
 * @property {boolean} loading
 */

export const getters = {
  /**
   * Returns the `clinicians` map.
   * @param {CliniciansStore} state
   * @returns {Clinician[]}
   */
  getClinicians: (state) => state.clinicians,

  /**
   * Returns a clinician given a search object
   * @param {CliniciansStore} state - The clinician store
   * @returns {function({ prop: 'uuid'|'email'|'id', value: string }): Clinician|undefined} A function that takes a search object and returns the corresponding clinician, or undefined if not found.
   */
  getClinician: (state) => (search) => {
    return state.clinicians.find(
      (clinician) => clinician[search.prop] === search.value
    )
  },

  /**
   * Returns the current error state.
   * @param {CliniciansStore} state
   * @returns string
   */
  getError: (state) => state.error,

  /**
   * Returns the current loading state.
   * @param {CliniciansStore} state
   * @returns boolean
   */
  getLoading: (state) => state.loading,
}

export const mappedGetters = mapGetters(Object.keys(getters))
export default getters
