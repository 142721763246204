import actions from './actions'
import mutations from './mutations'
import state from './state'

export const NAMESPACE = 'reporters'
export default {
  namespaced: true,
  actions,
  mutations,
  state,
}
