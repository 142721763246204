import vueEnv from '@/utils/env'

const Zendesk = {
  install(Vue) {
    const zendeskKey = vueEnv.zendeskKey
    let enabled = !!zendeskKey
    let isLoaded = false

    function initialize(opts = {}) {
      if (isLoaded || !enabled) {
        return
      }

      window.zESettings = opts

      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.id = 'ze-snippet'
      script.src =
        'https://static.zdassets.com/ekr/snippet.js?key=' + zendeskKey

      const first = document.getElementsByTagName('script')[0]
      first.parentNode.insertBefore(script, first)
      isLoaded = true
    }
    function activate() {
      window.zE.activate()
    }

    Vue.prototype.$zendesk = {
      initialize,
      activate,
      enabled,
    }
  },
}

export default Zendesk
