import * as types from './mutation-types'

export default {
  [types.SET_REPORTERS](state, data) {
    state.data = data
  },
  [types.SET_LOADING](state, loading) {
    state.loading = loading
  },
  [types.SET_ERROR](state, error) {
    state.error = error
  },
}
