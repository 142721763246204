/*
Sets a $clinician variable on Vue.prototype allowing it to be accessed throughout app
*/

import { dayjs, formatDate } from '@/utils/Date'

import {
  CLINICIAN_BREAK_SET,
  CLINICIAN_SET,
} from '@/store/mutations/clinician/constants'
import axios from 'axios'

function formatDateRange(year, month, range) {
  if (!year && !month && !range) return { start: undefined, end: undefined }
  const format = 'YYYY-MM-DD'

  let start = '',
    end = ''
  if (range?.start && range?.end) {
    start = formatDate(dayjs(range.start), format)
    end = formatDate(dayjs(range.end), format)
  } else {
    start = formatDate({ year, month: month - 1, day: 1 }, format)
    end = formatDate({ year, month, day: '0' }, format)
  }
  return { start, end }
}

// TODO: Handle errors that are being returned
const Clinician = {
  install(Vue) {
    const proto = Vue.prototype
    proto.$clinician = {
      async getProfileData() {
        const response = await proto.$request('internal/me')
        proto.$store.commit(CLINICIAN_SET, response)
        return response
      },
      async getFieldValues(fieldName) {
        return await proto.$request(`internal/options/${fieldName}`)
      },
      async update(data, id) {
        const response = await proto.$request(`internal/clinicians/${id}`, {
          method: 'patch',
          data,
        })

        proto.$store.commit(CLINICIAN_SET, response)
      },
      async unpause() {
        const response = await proto.$request('internal/me/unpause', {
          method: 'post',
        })
        proto.$store.commit(CLINICIAN_SET, response)
      },
      async toggleOnline(available) {
        const { available: availableResponse, on_shift: onShift } =
          await proto.$request('internal/me/availability', {
            method: 'patch',
            data: { available },
          })

        proto.$store.commit('SET_CLINICIAN_PROP_AVAILABLE', availableResponse)
        proto.$store.commit('SET_CLINICIAN_PROP_ON_SHIFT', onShift)
        const snackbarMessage = availableResponse
          ? 'You are online'
          : 'You are offline'

        proto.$snackbar.show(snackbarMessage)
      },
      async loadEarningsSummary(client) {
        try {
          const { data } = await proto.$request('internal/earnings/summary', {
            params: {
              client,
            },
          })
          proto.$store.commit('SET_EARNINGS_PROP_SUMMARY', data)
        } catch (e) {
          proto.$dd.addError(e)
          return e
        }
      },
      async loadEarningsPeriodSummary(period) {
        try {
          const {
            data,
            meta: { total },
          } = await proto.$request('internal/earnings/summary/month', {
            params: {
              period,
            },
          })
          proto.$store.commit('SET_EARNINGS_PROP_SUMMARY_MONTH', {
            data,
            total,
          })
        } catch (e) {
          proto.$dd.addError(e)
          return e
        }
      },
      async loadEarningsHistory({
        year,
        month,
        range,
        client,
        page_size,
        cursor = true,
        next,
        previous,
      }) {
        const { start, end } = formatDateRange(year, month, range)
        try {
          let params = {}

          if (cursor) {
            params = {
              client,
              start,
              end,
              next,
              previous,
              cursor_on: cursor,
              page_size,
            }
          }

          const data = await proto.$request('internal/earnings', {
            params,
          })

          proto.$store.commit('SET_EARNINGS_PROP_HISTORY', data)
        } catch (e) {
          proto.$dd.addError(e)
          return e
        }
      },
      async loadEarningsFilters({ year, month } = {}) {
        const { start, end } = formatDateRange(year, month)
        try {
          const data = await proto.$request('internal/earnings/filters', {
            params: { start, end },
          })
          if (data.data) {
            proto.$store.commit('SET_EARNINGS_PROP_FILTER_OPTIONS', data.data)
          } else {
            proto.$store.commit('SET_EARNINGS_PROP_FILTER_OPTIONS', data)
          }
        } catch (e) {
          proto.$dd.addError(e)
          return e
        }
      },
      async loadShifts(start, end, source_system) {
        const { data } = await proto.$request('internal/shifts', {
          params: {
            start,
            end,
            source_system,
          },
        })

        return data
      },
      async loadBookings(start, end) {
        const { data } = await proto.$request('internal/consults/bookings', {
          params: {
            start,
            end,
          },
        })

        return data
      },
      async loadCoverageBlocks(start, end, time_zone) {
        const { data } = await proto.$request('internal/coverage_blocks', {
          params: {
            start,
            end,
            time_zone,
          },
        })

        return data
      },
      async loadEarningsBlocks(start, end) {
        const { data } = await proto.$request('internal/earnings_blocks', {
          params: {
            start,
            end,
            page_size: 100,
          },
        })

        return data
      },
      createShift(shift) {
        return proto.$request('internal/shifts', {
          method: 'post',
          data: { shift },
        })
      },
      deleteShift(shift) {
        return proto.$request(`internal/shifts/${shift}`, {
          method: 'delete',
        })
      },
      async uploadPhoto(photo, uuid) {
        const { name, type } = photo

        const signedUrl = await proto.$request(
          `internal/clinicians/${uuid}/photo`,
          {
            method: 'post',
            data: { name, type },
          }
        )

        const data = new FormData()
        Object.keys(signedUrl.fields).forEach((key) =>
          data.append(key, signedUrl.fields[key])
        )
        data.append('file', photo)

        await axios.post(signedUrl.url, data)
        await this.update({ photo: signedUrl.fields.key }, uuid)
      },
      getEnrollments() {
        return proto.$request('internal/enrollments', {
          method: 'get',
        })
      },
      async takeBreak(duration) {
        const response = await proto.$request('internal/breaks', {
          method: 'post',
          data: { duration },
        })

        proto.$store.commit(CLINICIAN_BREAK_SET, response)
        return response
      },
      async endBreak(id) {
        try {
          const response = await proto.$request(`internal/breaks/${id}/end`, {
            method: 'patch',
          })
          proto.$store.commit(CLINICIAN_BREAK_SET, null)
          return response
        } catch (error) {
          proto.$dd.addError(error)
          if (error.title === 'break_not_found') {
            proto.$store.commit(CLINICIAN_BREAK_SET, null)
            return
          }

          throw error
        }
      },
      async openConsultUrl(uuid) {
        try {
          const response = proto.$request(`internal/consults/${uuid}`, {
            method: 'get',
          })
          return response
        } catch (e) {
          proto.$dd.addError(e)
          return e
        }
      },
      async getMetrics() {
        try {
          const response = await proto.$request('internal/metrics', {
            method: 'get',
          })
          return response.data
        } catch (e) {
          proto.$dd.addError(e)
          return e
        }
      },
    }
  },
}

export default Clinician
