import { createNamespacedHelpers } from 'vuex'
import { NAMESPACE } from './state'
const { mapGetters } = createNamespacedHelpers(NAMESPACE)

/**
 * @typedef {import('./state').ClientStore} ClientStore
 * @typedef {import('./state').Client} Client
 */

const getters = {
  clients({ data }) {
    return data ?? []
  },
  clientMap({ data }) {
    return data.reduce(
      (accum, cur) => ({
        ...accum,
        [cur.uuid]: cur.name,
      }),
      {}
    )
  },
  /**
   * Searches for and returns a client by UUID.
   * @param {ClientStore} state
   * @returns {function(string): Client|undefined} A function that takes a uuid string and returns the corresponding client, or undefined if not found.
   */
  getClient:
    ({ data }) =>
    (uuid) => {
      return data.find((client) => client.uuid === uuid)
    },
}

export const mappedGetters = mapGetters(Object.keys(getters))
export default getters
